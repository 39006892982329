._body_1wzfh_1 {
  position: relative;
  padding: 0 64px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center; }

._title_1wzfh_9 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000624; }

._bodyText_1wzfh_16 {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000624;
  text-align: center; }

._buttonContainer_1wzfh_24 {
  padding: 18px 64px 36px;
  display: flex;
  justify-content: center;
  gap: 12px; }

._secondaryButton_1wzfh_30 {
  background-color: #e7f0ff;
  border-radius: 18px;
  color: #116dff; }

._secondaryButton_1wzfh_30:hover {
  background-color: #d6e6fe; }

._mainButton_1wzfh_38 {
  background-color: #116dff;
  border-radius: 18px;
  color: #fff; }

._mainButton_1wzfh_38:hover {
  background-color: #5999ff; }

._buttonContent_1wzfh_46 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  line-height: 24px;
  padding: 6px 24px;
  font-size: 16px;
  box-sizing: border-box; }

._liner_1wzfh_57 {
  color: #dfe5eb;
  border-top: 1px solid;
  margin: 0 auto; }

._footer_1wzfh_62 {
  display: flex;
  justify-content: center;
  padding: 12px 30px;
  gap: 4px;
  font-size: 14px;
  line-height: 18px; }

._infoText_1wzfh_70 {
  color: #3b4057; }

._ctaSupport_1wzfh_73 {
  color: #116dff; }
