._businessTypeInput_ojecn_1 {
  display: flex; }
  ._businessTypeInput_ojecn_1 > div {
    width: 100%; }
  ._businessTypeInput_ojecn_1 input {
    border: 1px solid #d6e6fe !important;
    border-radius: 4px !important;
    padding: 6px 9px 6px 45px !important;
    height: 36px !important;
    font-size: 16px !important; }

._visible_ojecn_12 {
  display: inline-block; }

._preloaderWrapper_ojecn_15 {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 27px; }
