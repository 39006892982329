:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._presetMetaDataSection_88e8k_7 {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  ._presetMetaDataSection_88e8k_7 > div {
    display: inline-block; }
  ._presetMetaDataSection_88e8k_7 ._presetTitle_88e8k_13 {
    width: 200px; }
  ._presetMetaDataSection_88e8k_7 ._layoutFamily_88e8k_15 {
    width: 150px; }
  ._presetMetaDataSection_88e8k_7 ._animationKit_88e8k_17 {
    width: 210px; }
  ._presetMetaDataSection_88e8k_7 ._presetCategories_88e8k_19 {
    width: 160px; }
  ._presetMetaDataSection_88e8k_7 ._styleRules_88e8k_21 {
    width: 210px; }
  ._presetMetaDataSection_88e8k_7 ._presetContentVolume_88e8k_23 {
    width: 130px; }
  ._presetMetaDataSection_88e8k_7 ._isPublished_88e8k_25 {
    width: 100px; }
