._hint_14wpg_1 {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

._hintIcon_14wpg_6 {
  width: 24px;
  height: 24px;
  margin-right: 12px; }
  ._hintIcon_14wpg_6 > svg {
    fill: #333853; }

._hintText_14wpg_13 {
  font-size: 14px;
  line-height: 18px;
  color: #333853; }
