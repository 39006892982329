._adjustStylePanel_1ooyw_1 {
  height: 453px;
  overflow: hidden;
  position: relative; }

._contentContainer_1ooyw_6 {
  width: 100%;
  position: absolute;
  height: 100%; }

._contentInnerContainer_1ooyw_11 {
  overflow-y: auto;
  position: absolute;
  display: inline-block;
  left: 48px;
  top: 0;
  height: 100%;
  width: calc(100% - 48px);
  border-radius: 0 0 4px 0;
  background: #fff; }

._contentHeader_1ooyw_22 .control-label-base {
  font-size: 16px; }
