._textList_2l9n_1 {
  width: 100%; }
  ._textList_singlePanel_2l9n_3 {
    padding: 24px;
    box-sizing: border-box; }
    ._textList_singlePanel_2l9n_3 ._textList__textWr_2l9n_6 {
      padding: 24px 0; }
      ._textList_singlePanel_2l9n_3 ._textList__textWr_2l9n_6:first-child {
        padding-top: 0; }
      ._textList_singlePanel_2l9n_3 ._textList__textWr_2l9n_6:last-child {
        padding-bottom: 0; }
    ._textList_singlePanel_2l9n_3 ._textList__itemWrapper_2l9n_12:not(:last-child) {
      padding-bottom: 24px; }
  ._textList_doublePanel_2l9n_14 hr._textList__divider_2l9n_14 {
    width: calc(100% - 40px);
    margin-left: 20px; }
