._paragraphs_xjb05_8 {
  display: flex;
  flex-direction: column;
  gap: 12px; }
  ._paragraphs_xjb05_8 span {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px !important; }

._editButton_xjb05_17 {
  color: #116dff;
  text-underline-offset: 5px;
  text-decoration: underline;
  bottom: 2px;
  cursor: pointer;
  position: relative; }
  ._editButton_xjb05_17 div {
    font-size: 16px !important;
    font-weight: 500; }
