:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._stageOverlay_l8bjb_7 {
  display: flex;
  flex: 1;
  z-index: 1;
  background-color: #ffffff;
  opacity: 0;
  transition: opacity 3s ease-out; }
  ._stageOverlayAnimateIn_l8bjb_14 {
    opacity: 0.4; }
