._footer_4ti8s_1 {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  flex-direction: column;
  background-color: white;
  align-items: center; }
  ._footer_4ti8s_1 ._button_4ti8s_8 {
    padding: 12px 0 12px 0; }
