@keyframes _fadeIn_4b1pm_1 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._aiMessageContainer_4b1pm_14 {
  position: relative; }
  ._aiMessageContainer_4b1pm_14._fade_4b1pm_16 {
    animation: _fadeIn_4b1pm_1 300ms cubic-bezier(0.33, 1, 0.68, 1); }
  ._aiMessageContainer_4b1pm_14 ._aiMessage_4b1pm_14 {
    display: flex;
    flex-direction: row;
    gap: 18px;
    padding: 18px 24px;
    position: relative;
    z-index: 2;
    align-items: baseline; }
  ._aiMessageContainer_4b1pm_14 ._ai-icon-wrapper_4b1pm_26 {
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center; }
  ._aiMessageContainer_4b1pm_14 ._aiMessageContent_4b1pm_32 {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    position: relative; }
  ._aiMessageContainer_4b1pm_14 ._aiMessageBackground_4b1pm_38 {
    background-color: #f8f8fa;
    transition: height 200ms ease-out;
    border: 1px solid transparent;
    border-radius: 20px;
    box-shadow: 0 0 0 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;
    min-height: 68px;
    box-sizing: border-box; }
  ._aiMessageContainer_4b1pm_14 ._paragraphsAndChildren_4b1pm_50 {
    display: flex;
    flex-direction: column;
    gap: 18px; }
  ._aiMessageContainer_4b1pm_14 ._actionsContainer_4b1pm_54 {
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms ease-out, margin-top 300ms ease-out;
    margin-top: -3px; }
    ._aiMessageContainer_4b1pm_14 ._actionsContainer_4b1pm_54._actionsContainerVisible_4b1pm_59 {
      opacity: 1;
      visibility: visible;
      margin-top: 0; }
  ._aiMessageContainer_4b1pm_14 ._paragraphs_4b1pm_50 {
    display: flex;
    flex-direction: column;
    gap: 12px; }
    ._aiMessageContainer_4b1pm_14 ._paragraphs_4b1pm_50 span {
      line-height: 24px; }
  ._aiMessageContainer_4b1pm_14 ._title_4b1pm_69 {
    font-size: 14px;
    line-height: 32px; }
