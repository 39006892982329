:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._tagDesignersSectionsPanel_19yb0_7 {
  width: 500px;
  height: auto; }
  ._tagDesignersSectionsPanel_19yb0_7 .focus-panel-frame-content {
    display: flex;
    flex-direction: column;
    align-items: center; }

._submitButton_19yb0_15 {
  margin: 20px; }

._dropDownWrapper_19yb0_18 {
  margin: 6px 0;
  width: 100%; }
