._themePreview_1t5q8_1 {
  box-sizing: border-box;
  height: 100%;
  border-radius: 4px;
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; }

._contentContainer_1t5q8_10 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

._header_1t5q8_16 {
  margin-right: 12px; }

._heading_1t5q8_19 {
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 1.1; }

._palettePreviewContainer_1t5q8_24 {
  display: flex;
  flex-direction: row; }

._paragraph_1t5q8_28 {
  font-size: 12px;
  line-height: 1.1; }
