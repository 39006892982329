._root_vcxd3_1 {
  margin-bottom: 24px; }

._sectionHeader_vcxd3_4 {
  padding-top: 24px;
  padding-bottom: 18px;
  flex-grow: 1; }

._sectionHeaderContainer_vcxd3_9 {
  display: flex;
  align-items: center; }

._divider_vcxd3_13 {
  width: 100%;
  margin-top: 30px !important; }

._content_vcxd3_17 {
  padding-top: 18px; }
