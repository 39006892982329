:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._button_y21vr_7, ._buttonEdit_y21vr_7 {
  position: absolute;
  right: -1px;
  bottom: -7px;
  width: 30px;
  height: 30px;
  padding: 0;
  background: #fff;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 1px 20px 0 #00000014, 0 2px 10px 0 #00000014, 0 1px 5px 0 #0000001f;
  cursor: pointer; }
  ._button_y21vr_7 circle, ._buttonEdit_y21vr_7 circle {
    fill: #fff; }
  ._button_y21vr_7 path, ._buttonEdit_y21vr_7 path {
    fill: #0057e1; }
  ._button_y21vr_7:hover circle, ._buttonEdit_y21vr_7:hover circle {
    fill: #fff; }

._root_y21vr_26 {
  position: relative;
  display: inline-block; }

._fileInvalidIcon_y21vr_30 {
  height: 24px;
  width: 24px; }

._errorNotification_y21vr_34 {
  display: flex;
  align-items: center;
  margin-top: 23px;
  margin-bottom: -12px;
  max-width: 300px; }
  ._errorNotification_y21vr_34 ._errorText_y21vr_40 {
    position: relative;
    width: 100%;
    margin-left: 8px;
    font-size: 14px;
    line-height: 1.3em;
    color: #e62214;
    font-weight: 400; }

._fileInput_y21vr_49 {
  display: none; }

._contentPlaceholder_y21vr_52 {
  position: relative;
  width: 75px;
  height: 75px;
  background-color: #7a7a7a;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  ._contentPlaceholder_y21vr_52 span {
    font-size: 10px;
    line-height: 1.4em;
    color: #fff;
    opacity: 0.5;
    font-weight: 700;
    width: -webkit-min-content;
    width: min-content;
    text-align: center; }

._contentWrapper_y21vr_72 {
  position: relative;
  box-sizing: border-box;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 50%; }

._image_y21vr_81 {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  cursor: pointer; }

._buttonEdit_y21vr_7 svg {
  margin-bottom: 1px;
  margin-left: 2px; }

._buttonEdit_y21vr_7:focus ._menu_y21vr_95 {
  transform: translateY(0);
  visibility: visible;
  opacity: 1; }

._buttonEdit_y21vr_7:hover {
  background-color: #fff; }

._menu_y21vr_95 {
  position: absolute;
  top: 0;
  z-index: 2;
  margin: 0;
  padding: 17px 13px;
  background: #fff;
  border-radius: 2px;
  transform: translateY(-5px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.15s ease;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 2px 10px rgba(0, 0, 0, 0.08), 0 1px 20px rgba(0, 0, 0, 0.08); }

._menuItem_y21vr_117 {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 12px;
  line-height: 1.5em;
  color: #17191c;
  cursor: pointer; }
  ._menuItem_y21vr_117 ._icon_y21vr_125 {
    height: 18px;
    width: 18px;
    text-align: center;
    margin-right: 8px; }
    ._menuItem_y21vr_117 ._icon_y21vr_125 path {
      fill: #000000; }
  ._menuItem_y21vr_117:hover {
    color: #0057e1; }
    ._menuItem_y21vr_117:hover path {
      fill: #0057e1; }
  ._menuItem_y21vr_117:not(:last-child) {
    margin-bottom: 16px; }
  ._menuItem_y21vr_117 > svg {
    margin-right: 8px; }
  ._menuItem_y21vr_117 > ._menuItemText_y21vr_140 {
    white-space: nowrap; }

._loaderWrap_y21vr_143 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #fff; }

._loader_y21vr_143 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  color: #fff;
  transform: translate(-50%, -50%); }
  ._loader_y21vr_143 > svg {
    animation: _spin_y21vr_1 1s linear infinite; }
