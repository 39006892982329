:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_b4xjl_7 {
  padding: 30px 25px 0px 25px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }

._colorSection_b4xjl_14 {
  padding-top: 12px; }

._fixedButton_b4xjl_17 {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0; }

._newContainer_b4xjl_21 {
  height: 100%;
  display: flex;
  justify-content: space-between; }

._themeSection_b4xjl_26 {
  display: flex;
  flex-direction: column;
  padding: 18px 24px 24px;
  gap: 12px; }

._editSection_b4xjl_32 {
  padding: 24px;
  padding-bottom: 18px; }

._swatchesContainer_b4xjl_36 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

._swatch_b4xjl_36 {
  margin-bottom: 12px;
  width: 130px;
  height: 36px; }

._colorShadesContainer_b4xjl_47 {
  margin-bottom: 30px; }

._advancedSettings_b4xjl_50 ._iconWrapper_b4xjl_50 {
  display: flex;
  gap: 10px; }

._advancedSettings_b4xjl_50 ._symbolWrapper_b4xjl_54 {
  display: flex;
  border: 1px solid #116dff;
  border-radius: 50%; }
  ._advancedSettings_b4xjl_50 ._symbolWrapper_b4xjl_54 path {
    fill: #116dff; }

._paletteFromLogoBanner_b4xjl_61 {
  margin-bottom: 24px; }

._invertPaletteSection_b4xjl_64 {
  display: flex;
  padding: 24px;
  height: 75px;
  padding: 25px 60px;
  justify-content: center; }

._autoWireBtnContainer_b4xjl_71 {
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  ._autoWireBtnContainer_b4xjl_71 > * {
    margin-top: 8px; }
