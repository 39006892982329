._container_mog0q_1 {
  height: 100%; }

._headingSection_mog0q_4 {
  display: flex;
  flex-direction: column;
  padding: 18px 24px 24px;
  gap: 12px;
  position: -webkit-sticky;
  position: sticky;
  background-color: #fff;
  z-index: 2;
  top: 0; }

._editSection_mog0q_14 {
  flex: 1; }

._editThemesHeader_mog0q_17 {
  margin: 25px 24px 0 24px; }

._fontsList_mog0q_20 {
  margin: 12px 24px 0; }

._fixedButton_mog0q_23 {
  bottom: 0;
  position: -webkit-sticky;
  position: sticky; }
