._container_mhj3a_1 {
  display: grid;
  grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
  grid-template-rows: min-content 1fr min-content;
  grid-template-columns: 100%;
  grid-template-areas: 'modalErrorContainer' 'modalInputContainer' 'modalCtaContainer';
  height: 100%;
  width: 100%; }

._modalErrorContainer_mhj3a_9 {
  grid-area: modalErrorContainer; }

._modalInputContainer_mhj3a_12 {
  grid-area: modalInputContainer;
  padding: 24px;
  padding-top: 12px;
  overflow-y: auto;
  border-bottom-width: 1px;
  border-bottom-color: #dfe5eb;
  border-bottom-style: solid; }

._modalCtaContainer_mhj3a_21 {
  grid-area: modalCtaContainer;
  padding: 24px;
  display: flex;
  justify-content: flex-end; }
  ._modalCtaContainer_mhj3a_21 > *:first-child {
    margin-right: 12px; }

._modalItem_mhj3a_29:not(:last-child) {
  margin-bottom: 24px; }
