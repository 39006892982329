:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._html-code-input-editor-wrapper_1skl4_7 {
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  height: 108px;
  position: relative; }
  ._html-code-input-editor-wrapper_1skl4_7:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #80b1ff;
    opacity: 0; }
  ._html-code-input-editor-wrapper_1skl4_7:hover {
    border: 1px solid #d6e6fe;
    cursor: pointer; }
    ._html-code-input-editor-wrapper_1skl4_7:hover:after {
      opacity: 0.2; }
  ._html-code-input-editor-wrapper_1skl4_7._focused_1skl4_27 {
    height: 245px;
    border: 1px solid #80b1ff;
    box-shadow: inset 0 0 3px 0 rgba(0, 90, 132, 0.25); }
    ._html-code-input-editor-wrapper_1skl4_7._focused_1skl4_27:after {
      display: none; }

._html-code-input-preloader_1skl4_34 {
  position: absolute;
  top: calc(50% - 15px);
  left: 50%; }
