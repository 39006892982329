:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._skeleton_4ya5l_7 {
  width: 278px; }
  ._skeleton_4ya5l_7 rect {
    fill: #e1e3e6; }
  ._skeleton_4ya5l_7 ._arrow_4ya5l_11 {
    fill: #7e8796; }
