._user-icon_62att_1 {
  background: #a8caff;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center; }
  ._user-icon_62att_1 svg,
  ._user-icon_62att_1 img {
    height: 30px;
    width: 30px;
    border-radius: 15px; }
