@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._html-code-input-form_1ihmx_8 {
  padding: 12px; }

._html-code-input-form-header_1ihmx_11 {
  padding: 6px 12px;
  display: flex;
  justify-content: space-between; }

._html-code-input-form-header-text_1ihmx_16 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #3b4057;
  text-align: left;
  display: block;
  overflow: hidden; }

._html-code-input-form-footer_1ihmx_30 {
  display: flex;
  justify-content: center;
  padding: 6px 12px 6px 6px; }

._html-code-input-form-button_1ihmx_35 {
  font-size: 14px;
  height: 30px; }
