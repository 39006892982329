._businessItemAddressInput_1udqv_1 {
  display: block;
  padding: 8px;
  width: 96%;
  border: 1px solid #a8caff;
  font-size: 16px;
  cursor: pointer; }

._businessItemAddressInput_1udqv_1:hover {
  border-color: #a8caff;
  background-color: #e7f0ff; }
