._footerContainer_1lh48_1 {
  background: #fff0d1;
  position: absolute;
  bottom: 0%;
  width: calc(100% - 48px * 2);
  height: 62px;
  margin: 0px 48px 78px 48px;
  border-radius: 8px; }

._footer_1lh48_1 {
  padding: 18px 23px 18px 23px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

._contentContainer_1lh48_16 {
  display: flex;
  align-items: center; }

._infoIcon_1lh48_20 {
  display: flex;
  padding-right: 6px; }

._footerTitle_1lh48_24 {
  color: #000624;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; }

._button_1lh48_31 {
  display: flex;
  align-items: center; }

._footerCTA_1lh48_35 {
  color: #000624;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border: #000624 1px solid;
  border-radius: 12px;
  padding: 3px 12px;
  cursor: pointer; }

._footerCTA_1lh48_35:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline; }
