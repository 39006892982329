._sitePreviewLoader_cnfhz_1 {
  padding: 1px;
  background: transparent;
  position: absolute;
  top: 42px;
  height: calc(100% - 42px);
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: opacity 0.5s ease-in-out;
  background: #eceff3;
  z-index: 500;
  pointer-events: none; }
  ._sitePreviewLoader_cnfhz_1._visible_cnfhz_15 {
    opacity: 1; }
  ._sitePreviewLoader_cnfhz_1._hidden_cnfhz_17 {
    opacity: 0;
    transition-delay: 1s; }
