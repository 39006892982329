.darkTheme {
  --sg-message-background: #353639;
  --sg-base-modal-close-button-background-color: #ffffff1a;
  --sg-base-modal-header-divider-background-color: #ffffff1f;
  --sg-message-text-color: #ffffff;
  --sg-link-color: #aeff84;
  --sg-link-color-hover: #94d970;
  --sg-button-background: transparent;
  --sg-button-secondary-hover: #25252a80;
  --sg-button-text-color: #ffffff;
  --sg-button-border-color: #9c9ca2;
  --sparkle-icon-src: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/aiIcon_dark.svg');
  --sg-modal-card-background: #353639;
  --sg-modal-card-text-color-secondary: #9c9ca2;
  --sg-modal-card-text-color-primary: #ffffff;
  --sg-modal-card-input-background: #353639;
  --sg-modal-card-input-border-color: #9c9ca2;
  --sg-modal-card-border-color: transparent;
  --sg-modal-divider-color: #9c9ca2;
  --sg-modal-upload-logo-background: rgba(174, 255, 132, 0.1);
  --sg-modal-upload-logo-background-hover: rgba(174, 255, 132, 0.15);
  --sg-modal-upload-logo-border: #aeff84;
  --sg-modal-upload-logo-svg: #aeff84;
  --sg-modal-input-icon-color: #aeff84;
  --sg-disabled-text-color: #9c9ca2;
  --sg-background-image: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/sg-newui-background-dark.png');
  --sg-background-gradient: linear-gradient(77deg, #d5d3d300 70%, #71717163 99%),
    linear-gradient(121deg, #496d7ac9 0%, #4d6b7900 22%);
  --sg-site-preview-background: rgba(167, 165, 165, 0.1);
  --sg-site-preview-shadow: 0px 22px 67px 0px rgba(246, 255, 237, 0.47);
  --sg-site-preview-decor-svg: #9c9ca2;
  --sg-site-preview-device-selected-background: #515254;
  --sg-site-preview-device-selected: #aeff84;
  --sg-site-preview-device-not-selected: #9c9ca2;
  --sg-checkbox-background: #aeff84;
  --sg-checkbox-hover: #aeff8426;
  --sg-dropdown-selected: #25252a;
  --sg-checkbox-background-disabled: #9c9ca2;
  --sg-checkbox-border-disabled: #9c9ca2;
  --sg-checkbox-icon-color: #000000;
  --sg-checkbox-icon-color-disabled: #cfd1dc;
  --sg-dots-loader-background-color: #25282a;
  --sg-dots-loader-dot-background-color: #999ca2;
  --sg-modal-footer-primary-button-background: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-background-hover: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-text: #000000;
  --sg-modal-footer-secondary-button-text: #ffffff;
  --sg-modal-footer-secondary-button-text-hover: #ffffffbf;
  --sg-wix-logo-colors: #ffffff;
  --sg-final-step-divider: #9c9ca2;
  --sg-final-step-input-border: #aeff84;
  --sg-final-step-input-background: #000000;
  --sg-forms-modal-background: linear-gradient(
      131deg,
      #5f8ea0b3 1%,
      #5f8ea04a 27%,
      #00000000 69%
    ),
    linear-gradient(0deg, #17181b 1%, #17181b 99%);
  --sg-modal-backdrop: rgba(0, 0, 0, 0.4);
  --sg-modal-card-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.2);
  --sg-tooltio-background: #25252a;
  --sg-tooltip-text-color: #ffffff; }

@keyframes _slideInFromRight_7nt2v_1 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

._finalStep_7nt2v_81 {
  height: 100%;
  box-sizing: border-box; }
  ._finalStep_7nt2v_81 ._back-button-wrap_7nt2v_84 {
    margin-left: 4px;
    margin-top: 42px; }
    ._finalStep_7nt2v_81 ._back-button-wrap_7nt2v_84 ._back-button_7nt2v_84 {
      color: var(--sg-modal-footer-secondary-button-text, #116dff); }
      ._finalStep_7nt2v_81 ._back-button-wrap_7nt2v_84 ._back-button_7nt2v_84:hover {
        color: var(--sg-modal-footer-secondary-button-text-hover, #5999ff); }
  ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 {
    text-align: center; }
    ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._image_7nt2v_93 {
      animation: _slideInFromRight_7nt2v_1 2s cubic-bezier(1, 0, 0.3, 1) 0ms 1; }
    ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._heading_7nt2v_95 {
      color: var(--sg-modal-card-text-color-primary, #000624); }
    ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._subHeading_7nt2v_97 {
      color: var(--sg-modal-card-text-color-secondary, #333853); }
    ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._domainInput_7nt2v_99 {
      background: var(--sg-final-step-input-background, #ffffff);
      border: 1px solid var(--sg-final-step-input-border, #0e5eff); }
      ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._domainInput_7nt2v_99 input {
        color: var(--sg-modal-card-text-color-primary, #000624); }
      ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._domainInput_7nt2v_99:hover {
        background-color: var(--sg-final-step-input-background, #ffffff) !important; }
    ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._dividerWrapper_7nt2v_106 {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--sg-final-step-divider, #acafc4);
      font-size: 10px;
      font-weight: 700;
      gap: 24px;
      padding: 0 24px; }
      ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._dividerWrapper_7nt2v_106 hr {
        width: 100%;
        background-color: var(--sg-final-step-divider, #acafc4);
        height: 1px;
        border: 0; }
    ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._button-content_7nt2v_120 {
      min-width: 126px;
      display: flex;
      justify-content: center; }

.lightTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._buttonsContainer_7nt2v_125 ._finalStepButton_7nt2v_125 {
  background: var(--sg-modal-footer-primary-button-background, linear-gradient(60deg, #116dff 0%, #116dff 10.81%, #116dff 59.39%, #2995e8 74.74%, #5ff2ae 99.69%)) !important;
  background-size: 200% 100% !important;
  background-position: right !important;
  transition: background-position 0.5s;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-color: var(--sg-button-background, #ffffff) !important;
  border-color: var(--sg-button-border-color, #d6e6fe) !important;
  color: var(--sg-modal-footer-secondary-button-text, #116dff) !important; }
  .lightTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._buttonsContainer_7nt2v_125 ._finalStepButton_7nt2v_125:hover {
    outline-color: var(--sg-button-border-color, #d6e6fe) !important;
    border-color: var(--sg-button-border-color, #d6e6fe) !important;
    background-position: left !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-color: var(--sg-modal-upload-logo-background, #f4f7ff) !important;
    border-color: var(--sg-button-border-color, #d6e6fe) !important; }
    .lightTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._buttonsContainer_7nt2v_125 ._finalStepButton_7nt2v_125:hover [data-hook='prefix-icon'] {
      fill: var(--sg-modal-footer-secondary-button-text, #116dff) !important; }

.lightTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._domainInput_7nt2v_99 button._connectDomainButton_7nt2v_147 {
  color: var(--sg-modal-footer-primary-button-text, #ffffff);
  background: var(--sg-modal-footer-primary-button-background, linear-gradient(60deg, #116dff 0%, #116dff 10.81%, #116dff 59.39%, #2995e8 74.74%, #5ff2ae 99.69%));
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s; }
  .lightTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._domainInput_7nt2v_99 button._connectDomainButton_7nt2v_147:hover {
    color: var(--sg-modal-footer-primary-button-text, #ffffff) !important;
    background-position: left; }

.darkTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._buttonsContainer_7nt2v_125 ._finalStepButton_7nt2v_125 {
  color: var(--sg-button-text-color, #000624) !important;
  background-color: var(--sg-button-background, #ffffff) !important;
  border-color: var(--sg-button-border-color, #d6e6fe) !important;
  outline-color: var(--sg-button-border-color, #d6e6fe) !important; }
  .darkTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._buttonsContainer_7nt2v_125 ._finalStepButton_7nt2v_125:hover {
    color: var(--sg-button-text-color, #000624) !important;
    background-color: var(--sg-button-secondary-hover, #ffffff) !important;
    border-color: var(--sg-button-text-color, #000624) !important;
    outline-color: var(--sg-button-text-color, #000624) !important; }
  .darkTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._buttonsContainer_7nt2v_125 ._finalStepButton_7nt2v_125 ._button-content_7nt2v_120 {
    --wsr-color-B00: var(--sg-modal-upload-logo-svg, #5999ff);
    --wsr-color-B30: var(--sg-button-border-color, #d6e6fe); }

.darkTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._domainInput_7nt2v_99 button._connectDomainButton_7nt2v_147 {
  color: var(--sg-modal-footer-primary-button-text, #ffffff);
  background: var(--sg-modal-footer-primary-button-background, linear-gradient(60deg, #116dff 0%, #116dff 10.81%, #116dff 59.39%, #2995e8 74.74%, #5ff2ae 99.69%));
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.5s; }
  .darkTheme ._finalStep_7nt2v_81 ._centered-content_7nt2v_91 ._domainInput_7nt2v_99 button._connectDomainButton_7nt2v_147:hover {
    color: var(--sg-modal-footer-primary-button-text, #ffffff) !important;
    background-position: bottom; }
