._scanAnimationKitPanel_ifpnb_1 {
  display: flex;
  flex-direction: column; }
  ._scanAnimationKitPanel_ifpnb_1 ._content_ifpnb_4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
  ._scanAnimationKitPanel_ifpnb_1 ._footer_ifpnb_9 {
    display: flex;
    justify-content: space-around; }

._preloader_ifpnb_13 {
  position: absolute;
  top: 50%;
  right: 50%; }
