._errorMessage_8h4qb_1 {
  display: flex;
  height: 42px;
  background: #fde3e1;
  box-shadow: 0px -1px 0px 0px #f69891 inset, 0px 1px 0px 0px #f69891 inset;
  align-items: center; }

._errorMessageContent_8h4qb_8 {
  display: flex;
  flex-direction: row;
  gap: 18px;
  margin: 9px 30px 9px 30px; }

._tryAgainButton_8h4qb_14 {
  border-radius: 100px;
  min-width: 77px;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  border-color: #868aa5 !important;
  border: 1px;
  border-style: solid;
  font-size: 12px;
  font-weight: 700; }
