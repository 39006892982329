._container_c3ps7_1 {
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0px;
  padding: 0px !important;
  font-family: Madefor, Helvetica Neue, sans-serif; }
  ._container_c3ps7_1 ._body_c3ps7_8 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column; }
  ._container_c3ps7_1 ._bodyAdi_c3ps7_18 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column; }
