._businessTypeInput_1hz9e_1 {
  min-width: 300px;
  max-width: 300px;
  width: 300px;
  display: none; }

._visible_1hz9e_7 {
  display: inline-block; }

._preloaderWrapper_1hz9e_10 {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 27px; }
