._chat_obtk9_8 {
  height: calc(100vh - 20px * 2);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box; }
  ._chat_obtk9_8._actionButtonsVisible_obtk9_17 {
    padding-bottom: 0; }

._footerWrapper_obtk9_20 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 590px;
  display: flex;
  justify-content: flex-end; }
