._siteCreationView_jtilu_1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2147483647;
  transition: visibility 0s 450ms, opacity 450ms linear;
  opacity: 1;
  visibility: visible;
  background-color: #fff; }
  ._siteCreationView_jtilu_1.invisible {
    opacity: 0;
    visibility: hidden; }
  ._siteCreationView_jtilu_1 span {
    font-family: Madefor;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent; }
  ._siteCreationView_jtilu_1 input {
    font-family: Madefor; }

._siteCreationViewContent_jtilu_31 {
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 250ms ease-in, visibility 250ms ease-in; }
  ._siteCreationViewContent_jtilu_31.visible {
    opacity: 1;
    visibility: visible; }
