._dashboardLoader_9rzxk_1 {
  height: 100vh;
  display: grid;
  grid-template-areas: 'header' 'main' 'footer';
  background: linear-gradient(249.1deg, #d4ffcd -16.01%, #d2e1ff 26.11%, #fbfcff 55.34%), linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)); }
  ._dashboardLoader_9rzxk_1 ._sphereLoader_9rzxk_6 {
    align-self: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center; }
