:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._layoutFamilyScanPanel_282ci_7 ._contentWrapper_282ci_7 {
  height: 100%;
  overflow-y: scroll; }

._layoutFamilyScanPanel_282ci_7 ._toggle_282ci_11 {
  padding: 14px 24px 18px; }

._layoutFamilyScanPanel_282ci_7 ._textInputWrapper_282ci_14 {
  background-color: #d6e6fe; }

._layoutFamilyScanPanel_282ci_7 ._textInput_282ci_14 {
  background-color: #fff; }

._layoutFamilyScanPanel_282ci_7 ._saveLayoutFamilyFooter_282ci_20 {
  height: 78px;
  border-top: 1px solid #dfe5eb; }
  ._layoutFamilyScanPanel_282ci_7 ._saveLayoutFamilyFooter_282ci_20 .composite-action-set-horizontal {
    justify-content: space-between; }
    ._layoutFamilyScanPanel_282ci_7 ._saveLayoutFamilyFooter_282ci_20 .composite-action-set-horizontal .control-button {
      height: 30px; }
    ._layoutFamilyScanPanel_282ci_7 ._saveLayoutFamilyFooter_282ci_20 .composite-action-set-horizontal .control-button.secondary {
      background-color: #e7f0ff;
      margin-left: 0; }
      ._layoutFamilyScanPanel_282ci_7 ._saveLayoutFamilyFooter_282ci_20 .composite-action-set-horizontal .control-button.secondary:hover {
        background-color: #d6e6fe; }
