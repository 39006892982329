._textKit_1semf_1 {
  padding: 16px 18px;
  border-radius: 4px;
  height: 90px;
  box-sizing: border-box; }

._heading_1semf_7 {
  font-size: 20px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

._paragraph_1semf_14 {
  font-size: 12px;
  line-height: 1.33;
  max-height: 2.66em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden; }
