@keyframes _slideInFromRight_1b44q_1 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

._domainFinalStep_1b44q_7 {
  overflow: none;
  display: flex;
  width: 100%;
  height: 100%; }
  ._domainFinalStep_1b44q_7 ._content_1b44q_12 {
    width: 100%; }
    ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._back-button_1b44q_14 {
      margin-left: 4px;
      margin-top: 42px; }
    ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      height: calc(100% - 2 * (42px + 24px));
      padding: 0 84px 0 60px; }
      ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 {
        display: flex;
        border-radius: 30px;
        margin: calc(42px + 24px) 0;
        flex-direction: column;
        gap: 24px; }
        ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._image_1b44q_30 {
          animation: _slideInFromRight_1b44q_1 2s cubic-bezier(1, 0, 0.3, 1) 0ms 1; }
        ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._actionsWrapper_1b44q_32 {
          display: flex;
          flex-direction: column;
          animation: _slideInFromRight_1b44q_1 2s cubic-bezier(1, 0, 0.3, 1) 150ms 1;
          margin-bottom: 36px;
          gap: 36px; }
        ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._buttons-wrapper_1b44q_38 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 18px; }
        ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._button-content_1b44q_43 {
          min-width: 126px;
          display: flex;
          justify-content: center; }
        ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._title_1b44q_47 {
          font-size: 28px;
          font-weight: 700;
          line-height: 36px;
          letter-spacing: 0;
          margin-top: 6px;
          white-space: normal;
          animation: _slideInFromRight_1b44q_1 2s cubic-bezier(1, 0, 0.3, 1) 50ms 1; }
        ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._inner-text_1b44q_55 {
          font-size: 16px;
          white-space: pre-wrap;
          animation: _slideInFromRight_1b44q_1 2s cubic-bezier(1, 0, 0.3, 1) 100ms 1;
          color: #3b4057; }
        ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._domainForm_1b44q_60 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          position: relative;
          align-items: center; }
          ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._domainForm_1b44q_60 ._domainInput_1b44q_66 {
            padding: 0px 6px 0px 18px;
            border-radius: 100px;
            border: 1px solid #116dff;
            width: 100%;
            height: 42px;
            color: #000624;
            box-sizing: border-box; }
            ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._domainForm_1b44q_60 ._domainInput_1b44q_66:focus-visible {
              outline: none; }
          ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._domainForm_1b44q_60 ._domainBtn_1b44q_76 {
            position: absolute;
            right: 6px;
            bottom: 5px; }
        ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._dividerWrapper_1b44q_80 {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #acafc4;
          font-size: 10px;
          font-weight: 700;
          gap: 24px;
          padding: 0 24px; }
          ._domainFinalStep_1b44q_7 ._content_1b44q_12 ._centered-content_1b44q_17 ._main-container_1b44q_24 ._dividerWrapper_1b44q_80 hr {
            width: 100%;
            background-color: #acafc4;
            height: 1px;
            border: 0; }
