:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._text_hhdqx_7 {
  text-align: center;
  color: #3b4057;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  margin: 0 12px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  ._textAnimateIn_hhdqx_16 {
    opacity: 1; }

[data-madefor='true'] ._text_hhdqx_7 {
  font-family: Madefor, Helvetica Neue, sans-serif; }
