:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._deck_5o8u2_7 {
  position: relative;
  height: 44px;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  ._deckAnimateIn_5o8u2_14 {
    opacity: 1; }
  ._deckWithBottomBorder_5o8u2_16 {
    border-bottom: 1px solid #dfe5eb; }

._logoAndLeftContent_5o8u2_19 {
  display: flex; }

._logo_5o8u2_19 {
  display: flex;
  align-items: center;
  padding-left: 11px;
  padding-right: 11px; }

._leftContent_5o8u2_28 {
  display: flex;
  align-items: center;
  padding-left: 24px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  ._leftContentAnimateIn_5o8u2_34 {
    opacity: 1; }

._centerContent_5o8u2_37 {
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  ._centerContentAnimateIn_5o8u2_42 {
    opacity: 1; }

._rightContent_5o8u2_45 {
  display: flex;
  align-items: center;
  margin-right: 18px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  ._rightContentAnimateIn_5o8u2_51 {
    opacity: 1; }
