._body_1l00x_1 {
  position: relative;
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 12px; }

._title_1l00x_8 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000624; }

._bodyText_1l00x_15 {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3b4057; }

._footer_1l00x_22 {
  padding: 0 30px 30px;
  display: flex;
  justify-content: flex-end;
  gap: 12px; }

._secondaryButton_1l00x_28 {
  background-color: #e7f0ff;
  border-radius: 15px;
  color: #116dff; }

._secondaryButton_1l00x_28:hover {
  background-color: #d6e6fe; }

._mainButton_1l00x_36 {
  background-color: #116dff;
  border-radius: 15px;
  color: #fff; }

._mainButton_1l00x_36:hover {
  background-color: #5999ff; }

._buttonContent_1l00x_44 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  line-height: 18px;
  padding: 0 18px;
  font-size: 14px;
  box-sizing: border-box; }
