:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

@keyframes _gradient-animation_w63pr_1 {
  0% {
    background-position: 0% 50%; }
  100% {
    background-position: 100% 50%; } }

._container_w63pr_13 {
  --border-color: #d8d9dc;
  display: flex;
  box-sizing: border-box;
  height: 100%;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  border: 1px solid #e1e3e6;
  background-color: #fff;
  border-color: var(--border-color);
  opacity: 1;
  transition: background 2s;
  position: relative; }
  ._container_w63pr_13:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 272px;
    height: 36px;
    opacity: 0;
    z-index: 0;
    transition: opacity 0.5s; }
  ._container_w63pr_13._paletteContainerEmtyInitial_w63pr_37 {
    opacity: 1; }
  ._container_w63pr_13._paletteContainerEmtyAnimationStart_w63pr_39 {
    opacity: 1; }
  ._container_w63pr_13._paletteContainerEmtyLoading_w63pr_41 {
    transition: box-shadow 1s;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.08), 0px 2px 10px 0px rgba(0, 0, 0, 0.08), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }
    ._container_w63pr_13._paletteContainerEmtyLoading_w63pr_41:after {
      transition: opacity 0.5s;
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      width: 272px;
      height: 36px;
      opacity: 0.5;
      background: linear-gradient(90deg, #dce0e3 37%, #ffffff 50%, #dce0e3 63%);
      background-size: 200% 100%;
      background-repeat: no-repeat;
      background-position: 0% 0%;
      animation: _gradient-animation_w63pr_1 1s ease infinite reverse;
      z-index: 0; }
  ._container_w63pr_13._paletteContainerEmtyLoadingEnd_w63pr_59 {
    transition: box-shadow 0.4s;
    box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0), 0px 2px 10px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0); }

._paletteItemEmpty_w63pr_63 {
  height: 36px;
  width: 45px;
  border-right: 1px solid var(--border-color); }
  ._paletteItemEmpty_w63pr_63:last-child {
    border-right: 0; }

._paletteItemColored_w63pr_70 {
  height: 36px;
  width: 45px; }
