:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._label_1u1q8_7 {
  display: flex;
  justify-content: space-between;
  padding: 18px 24px 0; }

._accordionSection_1u1q8_12 .accordion-section-header {
  padding-top: 0;
  padding-bottom: 18px; }

._accordionSectionContent_1u1q8_16 {
  background-color: #f7f8f8;
  margin-top: 18px; }
