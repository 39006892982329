._chatWidgetWrapper_1gz52_1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 1; }
  ._chatWidgetWrapper_1gz52_1 ._chatWidgetContainer_1gz52_7 {
    flex: 1;
    width: 100%;
    overflow-x: hidden; }

._bg_1gz52_12 {
  position: absolute;
  z-index: 0;
  inset: 0; }
