._overlay_13jyy_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(23, 25, 28, 0.3);
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0s;
  cursor: pointer; }
  ._overlay_13jyy_1:hover {
    opacity: 1; }
