@keyframes _smoothAppear_q4awo_1 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._loadingPalettesContainer_q4awo_7 {
  animation-name: _expand_q4awo_1;
  animation-timing-function: linear;
  animation-duration: 3s; }

@keyframes _expand_q4awo_1 {
  from {
    height: 60px; }
  to {
    height: 216px; } }

._loadingPalettes_q4awo_7:not(:last-child) {
  margin-bottom: 24px; }

._loadingPalettes_q4awo_7:first-child {
  margin-top: 18px; }

._loadingPalettes_q4awo_7:nth-child(2) {
  animation-delay: 1s; }

._loadingPalettes_q4awo_7:nth-child(3) {
  animation-delay: 2s; }

._loadingPalettes_q4awo_7:nth-child(4) {
  animation-delay: 3s; }

._generatedPalettes_q4awo_33:not(:last-child) {
  margin-bottom: 24px; }

._generatedPalettes_q4awo_33:first-child {
  margin-top: 18px; }

._promptsVersion_q4awo_39 {
  margin: 24px 0px; }
