.darkTheme {
  --sg-message-background: #353639;
  --sg-base-modal-close-button-background-color: #ffffff1a;
  --sg-base-modal-header-divider-background-color: #ffffff1f;
  --sg-message-text-color: #ffffff;
  --sg-link-color: #aeff84;
  --sg-link-color-hover: #94d970;
  --sg-button-background: transparent;
  --sg-button-secondary-hover: #25252a80;
  --sg-button-text-color: #ffffff;
  --sg-button-border-color: #9c9ca2;
  --sparkle-icon-src: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/aiIcon_dark.svg');
  --sg-modal-card-background: #353639;
  --sg-modal-card-text-color-secondary: #9c9ca2;
  --sg-modal-card-text-color-primary: #ffffff;
  --sg-modal-card-input-background: #353639;
  --sg-modal-card-input-border-color: #9c9ca2;
  --sg-modal-card-border-color: transparent;
  --sg-modal-divider-color: #9c9ca2;
  --sg-modal-upload-logo-background: rgba(174, 255, 132, 0.1);
  --sg-modal-upload-logo-background-hover: rgba(174, 255, 132, 0.15);
  --sg-modal-upload-logo-border: #aeff84;
  --sg-modal-upload-logo-svg: #aeff84;
  --sg-modal-input-icon-color: #aeff84;
  --sg-disabled-text-color: #9c9ca2;
  --sg-background-image: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/sg-newui-background-dark.png');
  --sg-background-gradient: linear-gradient(77deg, #d5d3d300 70%, #71717163 99%),
    linear-gradient(121deg, #496d7ac9 0%, #4d6b7900 22%);
  --sg-site-preview-background: rgba(167, 165, 165, 0.1);
  --sg-site-preview-shadow: 0px 22px 67px 0px rgba(246, 255, 237, 0.47);
  --sg-site-preview-decor-svg: #9c9ca2;
  --sg-site-preview-device-selected-background: #515254;
  --sg-site-preview-device-selected: #aeff84;
  --sg-site-preview-device-not-selected: #9c9ca2;
  --sg-checkbox-background: #aeff84;
  --sg-checkbox-hover: #aeff8426;
  --sg-dropdown-selected: #25252a;
  --sg-checkbox-background-disabled: #9c9ca2;
  --sg-checkbox-border-disabled: #9c9ca2;
  --sg-checkbox-icon-color: #000000;
  --sg-checkbox-icon-color-disabled: #cfd1dc;
  --sg-dots-loader-background-color: #25282a;
  --sg-dots-loader-dot-background-color: #999ca2;
  --sg-modal-footer-primary-button-background: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-background-hover: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-text: #000000;
  --sg-modal-footer-secondary-button-text: #ffffff;
  --sg-modal-footer-secondary-button-text-hover: #ffffffbf;
  --sg-wix-logo-colors: #ffffff;
  --sg-final-step-divider: #9c9ca2;
  --sg-final-step-input-border: #aeff84;
  --sg-final-step-input-background: #000000;
  --sg-forms-modal-background: linear-gradient(
      131deg,
      #5f8ea0b3 1%,
      #5f8ea04a 27%,
      #00000000 69%
    ),
    linear-gradient(0deg, #17181b 1%, #17181b 99%);
  --sg-modal-backdrop: rgba(0, 0, 0, 0.4);
  --sg-modal-card-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.2);
  --sg-tooltio-background: #25252a;
  --sg-tooltip-text-color: #ffffff; }

._wsrAddressInput_z6qgz_75 {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-radius: var(--wds-input-border-radius-default-medium, var(--wsr-border-radius-06, 6px));
  position: relative;
  border: solid 1px var(--sg-modal-card-input-border-color, #d6e6fe);
  background-color: var(--wds-color-fill-standard-tertiary, var(--wsr-color-D80, #ffffff));
  color: var(--wds-color-text-standard-primary);
  padding: 0 6px;
  height: 34px; }
  ._wsrAddressInput_z6qgz_75:focus-within {
    border-color: #3899ec;
    box-shadow: 0 0 0 3px #aadbfc;
    outline: 0; }
  ._wsrAddressInput_z6qgz_75 input {
    text-overflow: clip;
    padding: 5px 3px 5px 6px;
    border: none;
    width: 100%;
    color: var(--sg-modal-card-text-color-primary, #000624);
    background-color: transparent; }
    ._wsrAddressInput_z6qgz_75 input:focus, ._wsrAddressInput_z6qgz_75 input:active, ._wsrAddressInput_z6qgz_75 input:hover, ._wsrAddressInput_z6qgz_75 input:focus-visible {
      outline: none; }
  ._wsrAddressInput_z6qgz_75 ._iconWrapper_z6qgz_99 {
    display: flex !important;
    align-items: center;
    justify-content: center; }
    ._wsrAddressInput_z6qgz_75 ._iconWrapper_z6qgz_99 div {
      display: flex !important; }
    ._wsrAddressInput_z6qgz_75 ._iconWrapper_z6qgz_99 svg {
      fill: var(--sg-modal-footer-secondary-button-text, #116dff); }

.darkTheme ._wsrAddressInput_z6qgz_75:focus-within {
  box-shadow: none;
  border-color: white; }
