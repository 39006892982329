:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._doublePanel_1hw1s_7, ._doublePanel_leftWrapper_1hw1s_7 {
  display: flex;
  height: 100%; }

._doublePanel_leftWrapper_1hw1s_7 {
  border-right: 1px solid #dfe5eb; }

._doublePanel_left_1hw1s_7 {
  width: 288px;
  height: 100%; }

._doublePanel_right_1hw1s_18 {
  width: 371px;
  height: 100%; }
