._lobbyView_wvtk6_1 {
  width: 100%; }

._themeContainer_wvtk6_4 {
  display: flex;
  flex-direction: column; }

._theme_wvtk6_4 {
  height: 156px;
  box-sizing: border-box;
  padding: 22px 24px 12px;
  background-color: #f7f8f8; }

._currentThemePreview_wvtk6_14 {
  height: 91px;
  box-shadow: 0px 2px 7px rgba(23, 25, 28, 0.17);
  border-radius: 4px;
  cursor: pointer; }

._changeThemeCTA_wvtk6_20 {
  display: flex;
  justify-content: center;
  padding-top: 12px; }

._lobbyItemsTitle_wvtk6_25 {
  margin: 19px 24px 0px; }

._connectColorsThemeButton_wvtk6_28 {
  width: 100%;
  display: flex;
  justify-content: center; }
