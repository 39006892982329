:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._progressContainer_1e3p9_7 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 2px 7px rgba(23, 25, 28, 0.17);
  box-sizing: border-box;
  width: 472px;
  height: 111px;
  padding: 36px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  ._progressContainerAnimateIn_1e3p9_26 {
    opacity: 1; }
