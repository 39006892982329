:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._sectionTitle_9jl9x_7 {
  margin: 12px 24px; }

._widgets_9jl9x_10 {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  margin-left: 10px; }

._widget_9jl9x_10 {
  width: 150px; }
