:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionTitle_3s0v6_7 {
  margin: 12px 24px; }

._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._componentTag_3s0v6_10 {
  border-bottom: 1px solid #dfe5eb; }
  ._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._componentTag_3s0v6_10 ._componentTagHeading_3s0v6_12 {
    padding: 12px 24px; }
  ._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._componentTag_3s0v6_10 ._componentTagDropDown_3s0v6_14 {
    margin: 0 24px 18px; }
  ._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._componentTag_3s0v6_10 ._translationCheckBox_3s0v6_16 {
    padding: 0 24px 8px; }
  ._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._componentTag_3s0v6_10 ._listItemSection_3s0v6_18 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    padding: 6px 24px 12px; }
    ._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._componentTag_3s0v6_10 ._listItemSection_3s0v6_18 ._listItemInput_3s0v6_24 {
      width: 100px; }

._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._imageHeading_3s0v6_27 ._imageText_3s0v6_27 {
  padding: 12px 24px; }

._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._imageHeading_3s0v6_27 ._imageThumbnailWrapper_3s0v6_30 {
  margin-bottom: 12px; }
  ._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._imageHeading_3s0v6_27 ._imageThumbnailWrapper_3s0v6_30 ._imageThumbnail_3s0v6_30 {
    display: inline-block;
    margin: 0 24px; }

._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._snapshotBox_3s0v6_36 ._snapshotBoxTitle_3s0v6_36 {
  padding: 12px 24px; }

._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._snapshotBox_3s0v6_36 ._snapshotBoxThumbnailWrapper_3s0v6_39 {
  margin-bottom: 12px; }
  ._presetContentTagsSection_3s0v6_7 ._presetContentTagsSectionContent_3s0v6_10 ._snapshotBox_3s0v6_36 ._snapshotBoxThumbnailWrapper_3s0v6_39 ._snapshotBoxThumbnail_3s0v6_39 {
    display: inline-block;
    margin: 0 24px; }
