._story_1fe5q_1 {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center; }

._gifContainer_1fe5q_12 {
  background-position: center;
  background-size: contain;
  width: 220px;
  height: 220px;
  margin-bottom: 12px; }

._gifStory1_1fe5q_19 {
  background-image: url("https://static.parastorage.com/services/santa-resources/dist/editor/preloader/heavySites/step1.v3.gif"); }

._gifStory2_1fe5q_22 {
  background-image: url("https://static.parastorage.com/services/santa-resources/dist/editor/preloader/heavySites/step2.v3.gif"); }

._gifStory3_1fe5q_25 {
  background-image: url("https://static.parastorage.com/services/santa-resources/dist/editor/preloader/heavySites/step3.v3.gif"); }

._gifStory4_1fe5q_28 {
  background-image: url("https://static.parastorage.com/services/santa-resources/dist/editor/preloader/heavySites/step4.v3.gif"); }

._titlesContainer_1fe5q_31 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px; }

._mainTitle_1fe5q_38 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000624; }

._secondaryTitle_1fe5q_44 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3b4057; }

._story1_1fe5q_50 {
  animation: _animation1_1fe5q_1 32s infinite; }

._story2_1fe5q_53 {
  animation: _animation2_1fe5q_1 32s infinite; }

._story3_1fe5q_56 {
  animation: _animation3_1fe5q_1 32s infinite; }

._story4_1fe5q_59 {
  animation: _animation4_1fe5q_1 32s infinite; }

@keyframes _animation1_1fe5q_1 {
  0% {
    opacity: 0; }
  1%,
  24% {
    opacity: 1; }
  25% {
    opacity: 0; } }

@keyframes _animation2_1fe5q_1 {
  25% {
    opacity: 0; }
  26%,
  49% {
    opacity: 1; }
  50% {
    opacity: 0; } }

@keyframes _animation3_1fe5q_1 {
  50% {
    opacity: 0; }
  51%,
  74% {
    opacity: 1; }
  75% {
    opacity: 0; } }

@keyframes _animation4_1fe5q_1 {
  75% {
    opacity: 0; }
  76%,
  99% {
    opacity: 1; }
  100% {
    opacity: 0; } }
