._siteCreationBusinessCardMain_4kk66_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition: visibility 700ms, opacity 700ms linear;
  background-color: #fff; }
  ._siteCreationBusinessCardMain_4kk66_1.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }

._siteCreationBusinessCard_4kk66_1 {
  display: flex;
  width: 100%;
  height: calc(100% - 102px); }
  @media only screen and (max-height: 900px) {
    ._siteCreationBusinessCard_4kk66_1 {
      height: calc(100% - 82px); } }

._siteCreationBusinessCardContent_4kk66_24 {
  display: flex;
  position: relative;
  width: 50%;
  padding: 0;
  overflow-y: auto; }

._businessCardTitle_4kk66_31 {
  margin: auto; }

._content_4kk66_34 {
  display: flex;
  flex-direction: column;
  margin-top: calc(100vh / 780 * 140);
  padding: 0 calc( (100vw / 1280 * 110));
  width: 100%; }
  @media only screen and (max-width: 1220px) {
    ._content_4kk66_34 {
      padding: 0 calc( ( 100vw / 1220 * 70 )); } }
  @media only screen and (min-width: 1600px) {
    ._content_4kk66_34 {
      padding: 0 calc( (100vw / 1920 * 175)); } }
  @media only screen and (min-height: 900px) {
    ._content_4kk66_34 {
      margin-top: calc( (100vh / 1080 * 260)); } }
  @media only screen and (max-height: 780px) {
    ._content_4kk66_34 {
      margin-top: calc((100vh / 780 * 80)); } }

._businessItem_4kk66_53 {
  display: flex;
  margin-bottom: 36px; }
  ._businessItem_4kk66_53.logo {
    align-items: flex-start; }
    ._businessItem_4kk66_53.logo > label {
      margin-top: 35px; }
  ._businessItem_4kk66_53 ._email-invalid_4kk66_60 {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 24px; }
    ._businessItem_4kk66_53 ._email-invalid_4kk66_60 ._email-invalid-icon_4kk66_67 {
      height: 24px;
      width: 24px;
      position: absolute;
      top: 2px; }
    ._businessItem_4kk66_53 ._email-invalid_4kk66_60:hover {
      cursor: pointer; }
      ._businessItem_4kk66_53 ._email-invalid_4kk66_60:hover ._email-invalid-tooltip_4kk66_74 {
        display: inline-block; }
    ._businessItem_4kk66_53 ._email-invalid_4kk66_60 ._email-invalid-tooltip_4kk66_74 {
      display: none;
      background-color: #ffffff;
      color: #424851;
      text-align: center;
      padding: 18px;
      border-radius: 8px;
      position: absolute;
      bottom: 40px;
      box-shadow: 0 0 22px 0 #162d3d40;
      white-space: nowrap; }
      ._businessItem_4kk66_53 ._email-invalid_4kk66_60 ._email-invalid-tooltip_4kk66_74:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -9px;
        border-width: 9px;
        border-style: solid;
        border-color: #ffffff transparent transparent transparent; }
  ._businessItem_4kk66_53.businessName {
    margin-bottom: 34px; }
    ._businessItem_4kk66_53.businessName label {
      margin-top: 6px; }
    ._businessItem_4kk66_53.businessName input {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.3em;
      height: 26px; }
      ._businessItem_4kk66_53.businessName input::placeholder {
        font-size: 22px; }
  ._businessItem_4kk66_53 .business-item-input {
    border: 0;
    outline: 0;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 1.15;
    color: #17191c;
    font-weight: 500; }
  ._businessItem_4kk66_53 label {
    margin-top: 2px;
    flex-shrink: 0;
    width: 100px;
    padding-right: 24px;
    line-height: 22px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    display: flex;
    align-items: center; }
    ._businessItem_4kk66_53 label._socialLabel_4kk66_126 {
      align-items: start; }
    ._businessItem_4kk66_53 label span {
      color: #7e8796;
      font-size: 12px;
      line-height: 1.5em;
      font-weight: 700; }
  ._businessItem_4kk66_53 input {
    border: 0;
    outline: 0;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 1.15;
    height: 24px;
    max-width: 280px; }
    ._businessItem_4kk66_53 input::placeholder {
      color: #bec2ca;
      opacity: 1; }
  @media only screen and (min-width: 1600px) {
    ._businessItem_4kk66_53.businessName input {
      font-size: 24px;
      height: 28px; }
      ._businessItem_4kk66_53.businessName input::placeholder {
        font-size: 24px; }
    ._businessItem_4kk66_53 .business-item-input {
      font-size: 16px; }
    ._businessItem_4kk66_53 label span {
      font-size: 14px; }
    ._businessItem_4kk66_53 input {
      max-width: 420px; } }

.pac-container {
  z-index: 2147483647; }

.pac-icon-marker {
  display: none; }
