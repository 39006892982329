._chatView_hu27k_8 {
  position: relative;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0px 20px 40px 0px #202e6426;
  margin: 20px; }

._chatWrapper_hu27k_15 {
  padding-left: 20px;
  padding-right: 20px; }
