._socialLinkList_abzal_1 {
  width: 100%; }

._socialLinkItem_abzal_4 {
  display: flex;
  align-items: center;
  margin-left: -8px;
  margin-bottom: 10px; }
  ._socialLinkItem_abzal_4 .social-link-platform {
    min-width: 30px;
    margin-right: 4px; }
  ._socialLinkItem_abzal_4 ._url-invalid_abzal_12 {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 24px;
    margin-left: 8px; }
    ._socialLinkItem_abzal_4 ._url-invalid_abzal_12 ._url-invalid-icon_abzal_20 {
      height: 24px;
      width: 24px;
      position: absolute; }
    ._socialLinkItem_abzal_4 ._url-invalid_abzal_12:hover {
      cursor: pointer; }
      ._socialLinkItem_abzal_4 ._url-invalid_abzal_12:hover ._url-invalid-tooltip_abzal_26 {
        display: inline-block; }
    ._socialLinkItem_abzal_4 ._url-invalid_abzal_12 ._url-invalid-tooltip_abzal_26 {
      display: none;
      background-color: #ffffff;
      color: #424851;
      text-align: center;
      padding: 18px;
      border-radius: 8px;
      position: absolute;
      bottom: 28px;
      box-shadow: 0 0 22px 0 #162d3d40;
      white-space: nowrap; }
      ._socialLinkItem_abzal_4 ._url-invalid_abzal_12 ._url-invalid-tooltip_abzal_26:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -9px;
        border-width: 9px;
        border-style: solid;
        border-color: #ffffff transparent transparent transparent; }
  ._socialLinkItem_abzal_4 .symbol-TrashCan {
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: opacity 150ms ease-in; }
    ._socialLinkItem_abzal_4 .symbol-TrashCan path {
      fill: #20303c; }
  ._socialLinkItem_abzal_4 ._deleteLinkButton_abzal_55 {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 8px; }
    ._socialLinkItem_abzal_4 ._deleteLinkButton_abzal_55:hover ._deleteLinkTooltip_abzal_62 {
      display: inline-block; }
    ._socialLinkItem_abzal_4 ._deleteLinkButton_abzal_55 ._deleteLinkTooltip_abzal_62 {
      display: none;
      background-color: #ffffff;
      color: #424851;
      text-align: center;
      padding: 18px;
      border-radius: 8px;
      position: absolute;
      bottom: 40px;
      box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.2);
      white-space: nowrap; }
      ._socialLinkItem_abzal_4 ._deleteLinkButton_abzal_55 ._deleteLinkTooltip_abzal_62:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -9px;
        border-width: 9px;
        border-style: solid;
        border-color: #ffffff transparent transparent transparent; }
  ._socialLinkItem_abzal_4:hover .symbol-TrashCan {
    opacity: 1;
    visibility: visible; }

._socialInputWrapper_abzal_88 {
  width: 100%;
  max-width: 270px; }
  ._socialInputWrapper_abzal_88 input {
    border: 0;
    outline: 0;
    padding: 0;
    width: 100%;
    font-size: 14px;
    line-height: 1.3em;
    background: transparent; }
    @media only screen and (min-width: 1600px) {
      ._socialInputWrapper_abzal_88 input {
        font-size: 16px; } }

._addSocialButton_abzal_103 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  line-height: 1.15;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 22px;
  margin-left: -8px; }
  ._addSocialButton_abzal_103 span {
    color: #237aff;
    font-size: 14px;
    line-height: 1.3em; }
  @media only screen and (min-width: 1600px) {
    ._addSocialButton_abzal_103 span {
      font-size: 16px; } }

._addSocialButtonPlus_abzal_120 {
  margin-right: 6px;
  margin-left: 4px; }
  ._addSocialButtonPlus_abzal_120 circle {
    stroke: #116dff;
    fill: #116dff; }
  ._addSocialButtonPlus_abzal_120:hover circle {
    stroke: #3885ff;
    fill: #3885ff; }
  ._addSocialButtonPlus_abzal_120 span {
    color: #fff;
    font-size: 18px; }
