:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._paletteContainer_1c0d6_7 {
  display: flex;
  width: 272px;
  height: 36px;
  cursor: pointer;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #e1e3e6;
  border-radius: 4px;
  overflow: hidden;
  animation-name: _shadow_1c0d6_1;
  animation-duration: 2s;
  animation-delay: 0.5s; }

@keyframes _shadow_1c0d6_1 {
  0% {
    box-shadow: none; }
  50% {
    box-shadow: 0px 5px 20px #e1e3e6; }
  100% {
    box-shadow: none; } }

._paletteContainer_1c0d6_7:hover {
  box-shadow: 0px 5px 20px #e1e3e6; }

._paletteContainer_1c0d6_7 ._paletteItem_1c0d6_32 {
  animation-name: _blink_1c0d6_1;
  animation-duration: 2s;
  animation-delay: 0.5s; }

@keyframes _blink_1c0d6_1 {
  0% {
    background-color: #fff; }
  50% {
    background-color: #e7f0ff; }
  100% {
    background-color: #fff; } }

._paletteContainer_1c0d6_7:hover ._paletteItem_1c0d6_32 {
  background-color: #e7f0ff; }

._paletteItem_1c0d6_32 {
  height: 36px;
  width: 45px; }

._paletteContainer_1c0d6_7 > ._paletteItem_1c0d6_32:not(:last-child) {
  border-right: 1px solid #e1e3e6; }

._uploadField_1c0d6_55 {
  height: 108px !important; }

._uploadButton_1c0d6_58 {
  margin-top: 18px; }

._emptyStateContainer_1c0d6_61 {
  display: flex;
  flex-direction: column;
  align-items: center; }
