#_siteGenerationView_1yo21_10 div,
#_siteGenerationView_1yo21_10 span,
#_siteGenerationView_1yo21_10 textarea,
#_siteGenerationView_1yo21_10 button {
  font-family: Madefor, Helvetica Neue, sans-serif !important; }

._siteGenerationViewNew_1yo21_10 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2147483647;
  transition: visibility 0s 800ms, opacity 800ms linear;
  opacity: 1;
  visibility: visible;
  background-color: #f5f5f7; }
  ._siteGenerationViewNew_1yo21_10._hidden_1yo21_21 {
    opacity: 0;
    visibility: hidden; }

._siteGenerationView_1yo21_10 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2147483647;
  transition: visibility 0s 800ms, opacity 800ms linear;
  opacity: 1;
  visibility: visible;
  background-color: #f5f5f7; }
  ._siteGenerationView_1yo21_10._hidden_1yo21_21 {
    opacity: 0;
    visibility: hidden; }
  ._siteGenerationView_1yo21_10 span {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent; }
  ._siteGenerationView_1yo21_10 input,
  ._siteGenerationView_1yo21_10 a {
    font-family: Madefor, Helvetica Neue, sans-serif; }
