:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._oldFooter_1qiel_7 {
  display: flex;
  justify-content: flex-start;
  padding: 0 30px; }
  ._oldFooter_1qiel_7 .control-button2-wrapper {
    padding: 0; }
  ._oldFooter_1qiel_7 ._add-button_1qiel_13 {
    padding: 12px 0; }
    ._oldFooter_1qiel_7 ._add-button_1qiel_13 .symbol {
      width: 14px;
      fill: #116dff;
      margin-right: 15px; }

._footer_1qiel_20 {
  display: flex;
  padding: 9px 15px;
  justify-content: space-between; }
  ._footer_1qiel_20 ._button_1qiel_24 {
    display: flex;
    align-items: center; }
    ._footer_1qiel_20 ._button_1qiel_24 svg {
      margin-right: 4px;
      fill: #116dff; }
