._errorMessage_hl0vn_1 {
  display: flex;
  flex-direction: row;
  min-height: 30px;
  padding: 18px 30px 18px 30px;
  border-radius: 20px;
  gap: 18px;
  background: #fde3e1;
  align-items: center; }
  ._errorMessage_hl0vn_1 svg {
    align-self: flex-start; }

._tryAgainButton_hl0vn_13 {
  border-radius: 100px;
  white-space: nowrap;
  height: 24px;
  padding-left: 12px;
  padding-right: 12px;
  border-color: #868aa5 !important;
  border: 1px;
  border-style: solid;
  font-size: 12px;
  font-weight: 700; }
