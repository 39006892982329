._container_sn9dx_1 {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  grid-template-areas: 'modalConversationContainer' 'modalFooterContainer';
  height: 100%;
  width: 100%; }

._modalConversationContainer_sn9dx_9 {
  grid-area: modalConversationContainer;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 30px 30px 0 30px; }
  ._modalConversationContainer_sn9dx_9::-webkit-scrollbar {
    display: none; }

._modalFooterContainer_sn9dx_18 {
  grid-area: modalFooterContainer;
  padding: 0 30px 30px 30px; }
