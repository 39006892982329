:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._optimizeMobileTextContainer_zi9k7_7 {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 18px; }
  ._optimizeMobileTextContainer_zi9k7_7 ._optimizeMobileImage_zi9k7_12 {
    flex-shrink: 0; }
  ._optimizeMobileTextContainer_zi9k7_7 ._optimizeMobileText_zi9k7_7 {
    display: flex;
    flex-direction: column;
    gap: 6px; }
    ._optimizeMobileTextContainer_zi9k7_7 ._optimizeMobileText_zi9k7_7 ._infoTextContainer_zi9k7_18 {
      display: flex;
      align-items: flex-start;
      gap: 12px; }
