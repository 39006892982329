._palette_cdn73_1 {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 8px 10px;
  height: 64px; }
  ._palette_cdn73_1 svg path {
    fill: currentColor; }

._newPaletteDesign_cdn73_10 {
  height: 36px; }
