:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._skeleton_eq5w1_7 {
  width: 263px; }
  ._skeleton_eq5w1_7 rect {
    fill: #e1e3e6; }
