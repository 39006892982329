:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._emptyStateWrapper_qg630_7 {
  padding-top: 36px;
  height: 100%;
  box-sizing: border-box;
  background-color: white; }
