:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_1a03m_7 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  min-width: 980px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1982;
  background-color: #e1e3e6 !important; }
  ._container_1a03m_7:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 5;
    width: 500%;
    margin-left: -250%;
    background: linear-gradient(to right, transparent 40%, rgba(255, 255, 255, 0.5) 50%, transparent 60%) 50% 50%;
    animation: _gradientAnimation_1a03m_1 2.6s ease-in-out infinite;
    content: ' ';
    pointer-events: none; }
  ._container_1a03m_7._withoutBackground_1a03m_32 {
    background-color: transparent !important; }
  ._container_1a03m_7 ._header_1a03m_34 {
    position: relative; }
    ._container_1a03m_7 ._header_1a03m_34 ._progressBarContainer_1a03m_36 {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 7; }
  ._container_1a03m_7 ._body_1a03m_41 {
    display: flex;
    flex-grow: 1; }

@keyframes _gradientAnimation_1a03m_1 {
  0% {
    transform: translate3d(-30%, 0, 0); }
  100% {
    transform: translate3d(30%, 0, 0); } }
