._root_1ig5w_1 {
  padding: 14px 12px;
  display: flex; }

._icon_1ig5w_5 {
  background-color: #ebf2ff;
  border-radius: 4px;
  margin-right: 18px;
  width: 51px;
  height: 51px; }

._label_1ig5w_12 {
  flex: 1;
  display: flex;
  align-items: center; }
