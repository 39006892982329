._gray-box-in_9ctdv_1 {
  animation-name: _box-in_9ctdv_1;
  opacity: 0;
  animation-fill-mode: forwards; }

@keyframes _box-in_9ctdv_1 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._gray-box-out_9ctdv_12 {
  opacity: 0; }
