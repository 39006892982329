._message_8dnd6_8 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: 1px solid transparent; }

._paragraphs_8dnd6_14 {
  display: flex;
  flex-direction: column;
  gap: 12px; }
  ._paragraphs_8dnd6_14 span {
    line-height: 30px !important; }
