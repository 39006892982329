._labeledInputGroup_1vv4t_1 {
  margin: 0 -12px 0 -24px; }

._infoWrapper_1vv4t_4 {
  padding: 0 12px 0 0; }

._controlsWrapper_1vv4t_7 {
  display: flex;
  justify-content: space-between;
  padding: 12px 0 4px; }
  ._controlsWrapper_1vv4t_7 > * {
    width: 48%;
    max-width: 48%;
    display: flex;
    justify-content: flex-end; }
    ._controlsWrapper_1vv4t_7 > *:first-child {
      justify-content: flex-start; }
    ._controlsWrapper_1vv4t_7 > * > * {
      max-width: 100%; }
  ._controlsWrapper_1vv4t_7 .prefix + .control-text {
    max-width: calc(100% - 24px); }
