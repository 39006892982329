:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._paletteContainer_16j90_7 {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  border: 1px solid #e1e3e6;
  width: 272px;
  height: 36px;
  background-color: #fff; }

._paletteItemEmpty_16j90_19,
._paletteItemColored_16j90_20 {
  height: 36px;
  width: 45px; }

._paletteContainer_16j90_7 > ._paletteItemEmpty_16j90_19:not(:last-child) {
  border-right: 1px solid #e1e3e6; }

._paletteContainer1_16j90_27 > ._paletteItemEmpty_16j90_19 {
  animation-delay: 1s; }

._paletteContainer2_16j90_30 > ._paletteItemEmpty_16j90_19 {
  animation-delay: 2s; }

._paletteContainer3_16j90_33 > ._paletteItemEmpty_16j90_19 {
  animation-delay: 3s; }

._paletteContainer4_16j90_36 > ._paletteItemEmpty_16j90_19 {
  animation-delay: 4s; }
