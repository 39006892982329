._siteCreationLeftContent_7n1w6_1 {
  position: relative;
  display: flex;
  width: 50%;
  height: 100%;
  background-color: #ebeef3; }

._siteCreationWixLogo_7n1w6_8 {
  position: absolute;
  left: 42px;
  top: 42px; }

._leftContentTexts_7n1w6_13 {
  width: calc(60% + 2 * 42px);
  margin-top: calc(100vh / 780 * 240);
  margin-left: calc(100vw / 1280 * 122);
  padding-right: 130px; }
  ._leftContentTexts_7n1w6_13 .site-creation-left-content-title {
    font-size: 48px;
    line-height: 1.15em;
    display: block;
    font-weight: 700; }
  ._leftContentTexts_7n1w6_13 .site-creation-left-content-sub-title {
    margin-top: 18px;
    font-size: 16px;
    line-height: 1.5em;
    display: block; }
  @media only screen and (min-width: 1280px) and (max-width: 1600px) {
    ._leftContentTexts_7n1w6_13 {
      padding-right: 105px; } }
  @media only screen and (min-width: 1600px) {
    ._leftContentTexts_7n1w6_13 {
      margin-left: calc(100vw / 1920 * 186);
      padding-right: 225px; }
      ._leftContentTexts_7n1w6_13 .site-creation-left-content-title {
        font-size: 68px; }
      ._leftContentTexts_7n1w6_13 .site-creation-left-content-sub-title {
        margin-top: 28px;
        font-size: 18px;
        line-height: 24px; } }
