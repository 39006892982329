:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._socialLinkList_16ubg_7 {
  width: 100%; }

._tooltip_16ubg_10, ._socialItemInput_16ubg_10 ._inputTooltip_16ubg_10, ._deleteLinkButton_16ubg_10 ._deleteTooltip_16ubg_10 {
  display: none;
  background-color: #000000;
  color: #fff;
  text-align: center;
  padding: 12px 24px;
  border-radius: 8px;
  position: absolute;
  transform: translateX(50%);
  box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.2);
  white-space: nowrap; }
  ._tooltip_16ubg_10:after, ._socialItemInput_16ubg_10 ._inputTooltip_16ubg_10:after, ._deleteLinkButton_16ubg_10 ._deleteTooltip_16ubg_10:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -9px;
    border-width: 9px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent; }

._socialLinkItem_16ubg_31 {
  align-items: center;
  width: 100%;
  margin-bottom: 12px; }
  ._socialLinkItem_16ubg_31 .symbol-TrashCan {
    opacity: 0;
    visibility: hidden;
    cursor: pointer;
    transition: opacity 150ms ease-in; }
    ._socialLinkItem_16ubg_31 .symbol-TrashCan path {
      fill: #20303c; }
  ._socialLinkItem_16ubg_31:hover .symbol-TrashCan {
    opacity: 1;
    visibility: visible; }

._addSocialButton_16ubg_46 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  line-height: 1.15;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 22px; }
  ._addSocialButton_16ubg_46 span {
    color: #237aff;
    font-size: 14px;
    line-height: 1.3em; }

._addSocialButtonPlus_16ubg_59 {
  margin-right: 6px;
  margin-left: 4px; }
  ._addSocialButtonPlus_16ubg_59 span {
    color: #fff;
    font-size: 18px; }

._socialItemInput_16ubg_10 {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #d6e6fe;
  padding: 4px 9px; }
  ._socialItemInput_16ubg_10 > input {
    width: calc(100% - 48px);
    box-sizing: border-box;
    border: none;
    font-size: 16px; }
    ._socialItemInput_16ubg_10 > input:focus {
      outline: none; }
  ._socialItemInput_16ubg_10:focus-within {
    outline-width: 2px; }
  ._socialItemInput_16ubg_10._invalidBorder_16ubg_83 {
    border: 1px solid #e62214; }
    ._socialItemInput_16ubg_10._invalidBorder_16ubg_83:focus {
      outline: 2px solid #e62214; }
  ._socialItemInput_16ubg_10 ._socialItemInputIcon_16ubg_87 {
    width: 24px;
    height: 24px;
    position: relative; }
  ._socialItemInput_16ubg_10:hover ._inputTooltip_16ubg_10 {
    display: flex;
    transform: translateY(-100%) translateX(-32%); }

._deleteLinkButton_16ubg_10 {
  display: flex;
  align-items: center; }
  ._deleteLinkButton_16ubg_10 ._deleteLinkIcon_16ubg_98 {
    position: relative;
    height: 24px;
    width: 24px; }
  ._deleteLinkButton_16ubg_10:hover ~ ._inputTooltip_16ubg_10 {
    display: none; }
  ._deleteLinkButton_16ubg_10:hover ._deleteTooltip_16ubg_10 {
    display: flex;
    transform: translateY(-100%) translateX(-37%); }

._invalidLink_16ubg_108 {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 6px; }
  ._invalidLink_16ubg_108 ._invalidMessage_16ubg_114 {
    margin-left: 20px; }
  ._invalidLink_16ubg_108 ._invalidIcon_16ubg_116 {
    position: absolute;
    height: 18px;
    width: 18px;
    fill: #e62214; }
    ._invalidLink_16ubg_108 ._invalidIcon_16ubg_116 g {
      stroke: #fff;
      stroke-width: 3px; }
