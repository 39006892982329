:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

@keyframes _smoothAppear_1ddq2_1 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._logoPalette_1ddq2_13 {
  margin-bottom: 30px; }
  ._logoPalette_1ddq2_13._fromPromptExperimentOpen_1ddq2_15 {
    margin-bottom: 0px; }

._textField_1ddq2_18 {
  width: 100%;
  padding-bottom: 12px;
  max-height: 108px; }

._editText_1ddq2_23 {
  margin-top: 10px; }

._logoPaletteEmpty_1ddq2_26 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 6px; }

._selectedLogo_1ddq2_33 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 12px; }
  ._selectedLogo_1ddq2_33._animate_1ddq2_39 {
    animation-name: _smoothAppear_1ddq2_1;
    animation-duration: 1.5s; }

._selectedLogoLoading_1ddq2_43 {
  gap: 18px; }

._loadingContent_1ddq2_46 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px; }

._loaderContainer_1ddq2_52 {
  width: 110px;
  height: 6px;
  display: block;
  background-color: #d6e6fe;
  border-radius: 4px; }

._loaderStart_1ddq2_59 {
  display: block;
  height: 100%;
  width: 0%;
  background-color: #116dff;
  animation-name: _startLoading_1ddq2_1;
  animation-duration: 10s;
  border-radius: 4px 0px 0px 4px;
  animation-fill-mode: forwards; }

._loaderEnd_1ddq2_69 {
  display: block;
  height: 100%;
  width: 75%;
  background-color: #116dff;
  animation-name: _finishLoading_1ddq2_1;
  animation-duration: 6s;
  border-radius: 4px 0px 0px 4px;
  animation-fill-mode: forwards; }

._animatedChangeLogoBtn_1ddq2_79 {
  animation-name: _smoothAppear_1ddq2_1;
  animation-duration: 2s; }
