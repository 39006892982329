._button_1d7qz_1 {
  width: 30px;
  height: 30px;
  padding-top: 2px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  ._button_1d7qz_1 path {
    fill: #ffffff; }
  ._button_1d7qz_1:hover {
    background: #ffffff; }
    ._button_1d7qz_1:hover path {
      fill: #116dff; }

._root_1d7qz_18 {
  position: relative;
  display: inline-block; }

._fileInput_1d7qz_22 {
  display: none; }

._contentPlaceholder_1d7qz_25 {
  position: relative;
  width: 162px;
  height: 162px;
  background-color: #e7f0ff;
  border: 1px solid #e1e3e6;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  ._contentPlaceholder_1d7qz_25 span {
    font-size: 10px;
    line-height: 1.4em;
    color: #fff;
    opacity: 0.5;
    font-weight: 700;
    width: -webkit-min-content;
    width: min-content;
    text-align: center; }

._contentWrapper_1d7qz_45 {
  position: relative;
  box-sizing: border-box;
  width: 162px;
  height: 162px;
  overflow: hidden;
  border-radius: 8px; }
  ._contentWrapper_1d7qz_45:hover ._updateArea_1d7qz_52 {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: rgba(0, 0, 0, 0.25); }
  ._contentWrapper_1d7qz_45:hover ._image_1d7qz_62 {
    transform: scale(1.15); }

._image_1d7qz_62 {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #eff1f2;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  cursor: pointer; }

._uploading_1d7qz_75 {
  background-color: transparent;
  -webkit-backdrop-filter: grayscale(50%) blur(4px);
          backdrop-filter: grayscale(50%) blur(4px);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
