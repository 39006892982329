._container_11hbw_1 {
  display: grid;
  grid-template-rows: -webkit-min-content 1fr -webkit-min-content;
  grid-template-rows: min-content 1fr min-content;
  grid-template-columns: 100%;
  grid-template-areas: 'modalErrorContainer' 'modalInputContainer' 'modalCtaContainer';
  height: 100%;
  width: 100%; }

._modalErrorContainer_11hbw_9 {
  grid-area: modalErrorContainer; }

._modalInputContainer_11hbw_12 {
  grid-area: modalInputContainer;
  gap: 12px;
  display: flex;
  flex-direction: column; }

._modalCtaContainer_11hbw_18 {
  grid-area: modalCtaContainer;
  padding: 24px;
  display: flex;
  justify-content: flex-end; }
  ._modalCtaContainer_11hbw_18 > *:first-child {
    margin-right: 12px; }

._modalItem_11hbw_26:not(:last-child) {
  margin-bottom: 24px; }
