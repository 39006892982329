._modalWrapper_1dla7_1 {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background: rgba(121, 124, 128, 0);
  border-radius: 30px;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all 500ms ease-out;
  width: 100%;
  z-index: 100; }
  ._modalWrapper_1dla7_1 ._modalCard_1dla7_13 {
    background: #fff;
    border-radius: 30px;
    box-shadow: 0 3px 24px 0 rgba(0, 6, 36, 0.18);
    display: grid;
    left: 50%;
    max-height: 90%;
    position: absolute;
    top: 45%;
    transform: scale(0.875) translate(-50%, -50%);
    transition: opacity 500ms ease-out, transform 500ms ease-out;
    grid-template-areas: 'modalTitle' 'modalContent';
    grid-template-columns: 100%;
    grid-template-rows: 76px calc(100% - 76px);
    z-index: 100; }
    ._modalWrapper_1dla7_1 ._modalCard_1dla7_13 ._modalTitle_1dla7_28 {
      grid-area: modalTitle;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      border-bottom-width: 1px;
      border-bottom-color: #dfe5eb;
      border-bottom-style: solid; }
    ._modalWrapper_1dla7_1 ._modalCard_1dla7_13 ._modalContent_1dla7_42 {
      grid-area: modalContent;
      width: 100%;
      height: 100%; }
    ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._chat_1dla7_46 {
      opacity: 0;
      height: 630px;
      width: 560px; }
      ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._chat_1dla7_46._fadeIn_1dla7_50 {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%); }
      ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._chat_1dla7_46._fadeOut_1dla7_53 {
        opacity: 0;
        transform: scale(0.875) translate(-50%, -50%); }
    ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._siteProfile_1dla7_56 {
      opacity: 0;
      height: 594px;
      width: 560px; }
      ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._siteProfile_1dla7_56._fadeIn_1dla7_50 {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%); }
      ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._siteProfile_1dla7_56._fadeOut_1dla7_53 {
        opacity: 0;
        transform: scale(0.875) translate(-50%, -50%); }
    ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._siteStructure_1dla7_66 {
      opacity: 0;
      height: 606px;
      width: 400px; }
      ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._siteStructure_1dla7_66._fadeIn_1dla7_50 {
        opacity: 1;
        transform: scale(1) translate(-50%, -50%); }
      ._modalWrapper_1dla7_1 ._modalCard_1dla7_13._siteStructure_1dla7_66._fadeOut_1dla7_53 {
        opacity: 0;
        transform: scale(0.875) translate(-50%, -50%); }
  ._modalWrapper_1dla7_1._fadeIn_1dla7_50 {
    background: rgba(121, 124, 128, 0.4); }
  ._modalWrapper_1dla7_1._fadeOut_1dla7_53 {
    background: rgba(121, 124, 128, 0); }
