._container_1v00b_1 {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  border-radius: 4px;
  position: relative; }

._newPaletteContainer_1v00b_8 {
  box-sizing: border-box;
  border: 1px solid #e1e3e6; }

@keyframes _fadeIn_1v00b_1 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
