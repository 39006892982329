:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._skeleton_18w0m_7 {
  width: 285px; }
  ._skeleton_18w0m_7 rect,
  ._skeleton_18w0m_7 path {
    fill: #e1e3e6; }
