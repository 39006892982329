:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._scan-and-save-thumbnail_1wh8a_7 {
  margin: auto;
  border-radius: 6px;
  position: relative; }
  ._scan-and-save-thumbnail_1wh8a_7._with-border_1wh8a_11 {
    border: 1px solid #dfe5eb; }
