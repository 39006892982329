:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._groupTooltip_vlc1a_7:hover + ._groupContent_vlc1a_7 ._groupSequence_vlc1a_7 {
  position: relative; }
  ._groupTooltip_vlc1a_7:hover + ._groupContent_vlc1a_7 ._groupSequence_vlc1a_7:after {
    --indent: -4px;
    content: '';
    border: solid 2px #80b1ff;
    border-radius: 4px;
    position: absolute;
    top: var(--indent);
    left: var(--indent);
    bottom: var(--indent);
    right: var(--indent);
    box-shadow: inset 0 0 0 2px #fff; }

._container_vlc1a_21 {
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 36px;
  box-sizing: border-box; }

._iconButton_vlc1a_28 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-right: 15px;
  border-radius: 5px;
  background-color: var(--preview-color);
  cursor: pointer;
  box-sizing: border-box; }
  ._iconButton_vlc1a_28 svg path {
    fill: #fff; }
  ._iconButton_vlc1a_28._light_vlc1a_41 {
    border: 1px solid #dfe5eb; }
    ._iconButton_vlc1a_28._light_vlc1a_41 svg path {
      fill: #000624; }
  ._iconButton_vlc1a_28._highlighted_vlc1a_45 {
    position: relative;
    border: none; }
    ._iconButton_vlc1a_28._highlighted_vlc1a_45:after {
      --indent: -4px;
      content: '';
      border: solid 2px #80b1ff;
      border-radius: 4px;
      position: absolute;
      top: var(--indent);
      left: var(--indent);
      bottom: var(--indent);
      right: var(--indent);
      box-shadow: inset 0 0 0 2px #fff; }
  ._iconButton_vlc1a_28:hover {
    position: relative;
    border: none; }
    ._iconButton_vlc1a_28:hover:after {
      --indent: -4px;
      content: '';
      border: solid 2px #80b1ff;
      border-radius: 6px;
      position: absolute;
      top: var(--indent);
      left: var(--indent);
      bottom: var(--indent);
      right: var(--indent);
      box-shadow: inset 0 0 0 2px #fff; }

._groupsHeader_vlc1a_74 {
  margin-bottom: 24px; }

._groupSequence_vlc1a_7 {
  display: flex;
  position: relative;
  align-items: center;
  flex-grow: 1; }

._groupItem_vlc1a_83 {
  width: 20%;
  height: 12px;
  background-color: var(--color);
  position: relative;
  box-sizing: border-box;
  --edges-border-radius: 2px; }
  ._groupItem_vlc1a_83:first-child {
    border-top-left-radius: var(--edges-border-radius);
    border-bottom-left-radius: var(--edges-border-radius); }
  ._groupItem_vlc1a_83:last-child {
    border-top-right-radius: var(--edges-border-radius);
    border-bottom-right-radius: var(--edges-border-radius); }
  ._groupItem_vlc1a_83:hover {
    border-radius: var(--edges-border-radius); }

._groupContent_vlc1a_7 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }

._groupLabel_vlc1a_105 {
  margin-bottom: 5px; }

._groupItemInner_vlc1a_108 {
  width: 100%;
  height: 100%; }
  ._groupItemInner_vlc1a_108:hover {
    z-index: 1;
    cursor: pointer;
    position: relative; }
    ._groupItemInner_vlc1a_108:hover:after {
      --indent: -4px;
      content: '';
      border: solid 2px #80b1ff;
      border-radius: 4px;
      position: absolute;
      top: var(--indent);
      left: var(--indent);
      bottom: var(--indent);
      right: var(--indent);
      box-shadow: inset 0 0 0 2px #fff; }
  ._groupItemInner_vlc1a_108._active_vlc1a_126 {
    position: absolute;
    height: calc(100% + 4px);
    box-shadow: 0 1px 3px 0 rgba(22, 45, 61, 0.5);
    border-radius: 0;
    top: -2px;
    background-color: var(--color); }
    ._groupItemInner_vlc1a_108._active_vlc1a_126:after {
      --indent: -1px;
      content: '';
      position: absolute;
      top: var(--indent);
      left: var(--indent);
      bottom: var(--indent);
      right: var(--indent);
      border: solid 1px #fff;
      border-radius: 1px;
      z-index: 1;
      box-shadow: none; }

._groupItemLightColor_vlc1a_146 {
  border: 1px solid #dfe5eb; }
  ._groupItemLightColor_vlc1a_146:hover {
    border: none; }
