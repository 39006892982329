:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_m18w1_7 {
  width: 240px; }

._footer_m18w1_10 {
  display: flex;
  padding: 18px 24px;
  justify-content: space-between; }

._sliderContainer_m18w1_15 {
  padding-right: 24px; }

._sliderLabel_m18w1_18 {
  margin-bottom: 6px; }

._slider_m18w1_15 .colored-part {
  box-shadow: none !important;
  border: 1px solid #dfe5eb; }
