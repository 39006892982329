:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._selectedItem_1fthj_7 {
  cursor: pointer;
  position: relative;
  border-radius: 4px; }
  ._selectedItem_1fthj_7:hover ._selection_1fthj_11 {
    box-shadow: inset 0 0 0 2px #80b1ff, inset 0 0 0 4px white; }
  ._selectedItem_1fthj_7._selected_1fthj_7 ._selection_1fthj_11 {
    box-shadow: inset 0 0 0 2px #116dff, inset 0 0 0 4px white; }
  ._selectedItem_1fthj_7._disabled_1fthj_15 ._selection_1fthj_11 {
    box-shadow: inset 0 0 0 1px #e1e3e6; }

._selectedItemIcon_1fthj_18 {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 2; }
  ._selectedItemIcon_1fthj_18 g {
    fill: #116dff;
    stroke: #fff; }

._selection_1fthj_11 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #e1e3e6;
  transition: box-shadow 0.15s ease-in-out 0s; }
