._siteDescriptionLabel_vxldo_1 {
  font-size: 14px;
  line-height: 18px;
  color: #333853; }

._siteDescriptionTextArea_vxldo_6 {
  width: 100%;
  height: 100px;
  resize: none;
  color: #000624;
  border-color: #d6e6fe;
  margin-bottom: 18px;
  margin-top: 6px;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 12px; }
