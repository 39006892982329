:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._textItem_singlePanel_v4rfv_7 ._textItem__controls_v4rfv_7 {
  padding-top: 18px; }

._textItem_singlePanel_v4rfv_7 ._textItem__option_v4rfv_10 {
  padding-bottom: 6px; }

._textItem_doublePanel_v4rfv_13 {
  padding: 25px 24px 0 24px;
  transition: background-color 0.1s linear; }
  ._textItem_doublePanel_v4rfv_13 ._textItem__controlsWr_v4rfv_16 {
    height: 42px;
    padding-top: 6px;
    padding-bottom: 12px;
    box-sizing: border-box; }
  ._textItem_doublePanel_v4rfv_13 ._textItem__controls_v4rfv_7 {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear; }
  ._textItem_doublePanel_v4rfv_13:hover {
    background-color: #e7f0ff; }
    ._textItem_doublePanel_v4rfv_13:hover ._textItem__controls_v4rfv_7 {
      visibility: visible;
      opacity: 1; }
  ._textItem_doublePanel_v4rfv_13 ._textItem__option_v4rfv_10 {
    padding-bottom: 12px; }

._textItem__controls_v4rfv_7 {
  display: flex;
  justify-content: space-between;
  align-items: center; }

._textItem__text_v4rfv_38 {
  white-space: pre-wrap; }
