@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_8dylj_8 {
  width: 540px;
  position: fixed; }

._content_8dylj_12 {
  display: flex; }

._preview_8dylj_15 {
  display: flex;
  width: 300px;
  box-sizing: border-box;
  padding: 14px 0 14px 24px;
  background-color: #f7fbff; }

._previewHeight_8dylj_22 {
  height: 242px; }

._previewHeightLetterSpacing_8dylj_25 {
  height: 293px; }

._previewContent_8dylj_28 {
  display: flex;
  flex-direction: column;
  padding-right: 24px; }

._adjustments_8dylj_33 {
  width: 240px;
  height: 242px;
  box-sizing: border-box; }

._borderRight_8dylj_38 {
  border-right: 1px solid #dfe5eb; }

._borderTop_8dylj_41 {
  border-top: 1px solid #dfe5eb; }

._borderBottom_8dylj_44 {
  border-bottom: 1px solid #dfe5eb; }

._fontSize_8dylj_47 {
  padding: 14px 24px; }

._letterSpacing_8dylj_50 {
  padding: 14px 24px; }

._fontStyles_8dylj_53 {
  display: flex;
  padding: 20px 24px; }

._fontStyleControl_8dylj_57 {
  margin-right: 10px; }

._previewText_8dylj_60 {
  margin-top: 10px; }

._actions_8dylj_63 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px;
  box-sizing: border-box;
  padding: 14px 24px;
  border-top: 1px solid #dfe5eb; }

._fontFamilyPicker_8dylj_72 {
  position: relative; }

._resetStyleContainer_8dylj_75 {
  font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
  font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
  font-weight: var(--wbu-font-weight-regular);
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  text-align: right;
  font-weight: 300;
  position: absolute;
  z-index: 1;
  top: 17px;
  right: 24px; }
  ._resetStyleContainer_8dylj_75 ._resetStyleButton_8dylj_87 {
    color: #116dff;
    line-height: 21px; }
    ._resetStyleContainer_8dylj_75 ._resetStyleButton_8dylj_87:hover {
      cursor: pointer;
      text-decoration: underline; }
