:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_x265g_7 {
  padding: 30px 25px; }

._headerBehaviorIllustration_x265g_10 {
  position: relative;
  height: 114px;
  width: 114px; }
  ._headerBehaviorIllustration_x265g_10 img {
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0; }
