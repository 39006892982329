:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._header_7za2x_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 30px; }
  ._header_7za2x_7 ._previewDeviceChangeWrapper_7za2x_13 {
    top: 10px;
    left: 10px;
    display: flex; }
    ._header_7za2x_7 ._previewDeviceChangeWrapper_7za2x_13 ._previewDeviceChange_7za2x_13 {
      cursor: pointer; }
      ._header_7za2x_7 ._previewDeviceChangeWrapper_7za2x_13 ._previewDeviceChange_7za2x_13._deviceChangeSelected_7za2x_19 svg path {
        fill: #116dff; }
      ._header_7za2x_7 ._previewDeviceChangeWrapper_7za2x_13 ._previewDeviceChange_7za2x_13:hover svg path {
        fill: #116dff; }
      ._header_7za2x_7 ._previewDeviceChangeWrapper_7za2x_13 ._previewDeviceChange_7za2x_13._deviceChangeDisabled_7za2x_23 svg path {
        fill: #868aa5; }

._pageBuilder_7za2x_26 {
  margin: 20px 0;
  height: calc(100% - 20px * 2);
  display: flex;
  flex-direction: column;
  align-items: center; }
  ._pageBuilder_7za2x_26::-webkit-scrollbar {
    width: 0; }
  ._pageBuilder_7za2x_26 ._previewWrapper_7za2x_34 {
    position: relative; }
    ._pageBuilder_7za2x_26 ._previewWrapper_7za2x_34 ._badge_7za2x_36 {
      position: absolute;
      top: 0;
      left: 0; }

._noPreviewScreen_7za2x_41 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
