._logoImageContainer_1925s_1 {
  position: relative;
  width: 108px;
  height: 60px; }

._logoImageContainer_1925s_1 ._logoImage_1925s_1 {
  width: 100%;
  height: 100%; }

._logoImageHovered_1925s_10 {
  opacity: 0.5; }

._replaceButton_1925s_13 {
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px 39px;
  z-index: 1;
  align-items: end; }
