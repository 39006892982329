._overlay_osmrs_1 {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(23, 25, 28, 0.66);
  z-index: 1; }

._container_osmrs_13 {
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.15), 0 12px 17px 2px rgba(0, 0, 0, 0.1), 0 5px 22px 4px rgba(0, 0, 0, 0.09);
  z-index: 1;
  text-align: start; }
  ._container_osmrs_13._big_osmrs_23 {
    width: 600px; }
  ._container_osmrs_13._small_osmrs_25 {
    width: 510px; }

._header_osmrs_28 {
  position: relative;
  border-top: 6px solid #116dff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-top: 30px; }

._headerButtonBar_osmrs_35 {
  position: absolute;
  top: 6px;
  right: 9px;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

._headerButton_osmrs_35 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff; }

._headerButton_osmrs_35:hover {
  border-radius: 50%;
  background: #f7f8f8; }

._buttonSymbol_osmrs_53 {
  display: flex;
  align-items: center;
  justify-content: center; }
