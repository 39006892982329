:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._previewWrapper_1xovv_7 {
  position: relative;
  height: calc(100% - 75px);
  scrollbar-width: none;
  -ms-overflow-style: none; }
  ._previewWrapper_1xovv_7::-webkit-scrollbar {
    width: 0; }

._previewItemWrapper_1xovv_15 {
  margin-bottom: 20px;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  ._previewItemWrapper_1xovv_15._selected_1xovv_19 {
    border: 4px solid #5999ff; }

._noPreviewScreen_1xovv_22 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

._resultsCount_1xovv_28 {
  text-align: center;
  width: 100%; }
