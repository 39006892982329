:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._colorButton_ej9ux_7 {
  width: 130px;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #dfe5eb; }

._colorButtonInner_ej9ux_14 {
  width: 100%;
  height: 100%;
  background-color: var(--color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 12px; }
  ._colorButtonInner_ej9ux_14 svg path {
    fill: #fff; }
  ._colorButtonInner_ej9ux_14._light_ej9ux_25 svg path {
    fill: #000624; }
  ._colorButtonInner_ej9ux_14._active_ej9ux_27 {
    outline: solid 2px #116dff; }
  ._colorButtonInner_ej9ux_14 ._contextMenuBtn_ej9ux_29 {
    display: none; }
  ._colorButtonInner_ej9ux_14 ._contextMenuBtnOpen_ej9ux_31 {
    display: flex; }
  ._colorButtonInner_ej9ux_14:hover {
    cursor: pointer;
    outline: solid 2px #a8caff; }
    ._colorButtonInner_ej9ux_14:hover ._contextMenuBtn_ej9ux_29 {
      display: flex; }
