._progressBarContainer_18j3u_1 {
  position: absolute;
  bottom: 0;
  width: 100%; }

._progressBar_18j3u_1::before {
  width: 0;
  height: 24px;
  background: #116dff; }

._progressBarPreload_18j3u_11::before {
  width: 50%; }

._progressBar_18j3u_1 {
  height: 24px;
  background: #dfe5eb; }
