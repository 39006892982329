._aiTextGeneratorPanel_el4cm_1 {
  min-width: 305px;
  min-height: 576px; }
  ._aiTextGeneratorPanel_doublePanel_el4cm_4 {
    min-width: 660px; }
  ._aiTextGeneratorPanel__wrapper_el4cm_6 {
    display: flex;
    height: 100%; }

._preloader__wrapper_el4cm_10 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px; }
