:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_1n5tx_7 {
  position: relative;
  width: 100%;
  margin-top: 3px; }

._shadesContainer_1n5tx_12 {
  border: 1px solid #dfe5eb;
  display: flex;
  flex-direction: row;
  border-radius: 4px; }

._withBaseColors_1n5tx_18 {
  border-radius: 4px;
  border-left: none;
  border-right: none;
  margin: 0; }

._shade_1n5tx_12 {
  width: 33.33%;
  height: 12px;
  position: relative;
  box-sizing: border-box;
  background-color: var(--color); }
  ._shade_1n5tx_12:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  ._shade_1n5tx_12:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }

._withoutOriginalColorsShade_1n5tx_37:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

._withoutOriginalColorsShade_1n5tx_37:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

._withoutOriginalColorsShade_1n5tx_37:hover {
  border-radius: unset; }

._shadeInner_1n5tx_48 {
  width: 100%;
  height: 100%; }
  ._shadeInner_1n5tx_48:hover {
    cursor: pointer;
    position: relative;
    z-index: 1; }
    ._shadeInner_1n5tx_48:hover:after {
      --indent: -1px;
      content: '';
      border: 1px solid #ffffff;
      border-radius: 2px;
      position: absolute;
      top: var(--indent);
      left: var(--indent);
      bottom: var(--indent);
      right: var(--indent);
      box-shadow: 0px 1px 4px 0px #00000066; }
  ._shadeInner_1n5tx_48._active_1n5tx_66 {
    position: relative;
    z-index: 1; }
    ._shadeInner_1n5tx_48._active_1n5tx_66:after {
      --indent: -1px;
      content: '';
      border: 1px solid #ffffff;
      border-radius: 2px;
      position: absolute;
      top: var(--indent);
      left: var(--indent);
      bottom: var(--indent);
      right: var(--indent);
      box-shadow: 0px 1px 4px 0px #00000066; }
