._buttons_ctghw_1 {
  height: 42px;
  align-items: center;
  display: flex; }

._nonPrimaryButtons_ctghw_6 span {
  font-size: 18px;
  color: #162d3d;
  font-family: Madefor; }

._nonPrimaryButtons_ctghw_6:hover {
  cursor: pointer; }

._nonPrimaryButtons_ctghw_6.disabled {
  pointer-events: none; }
  ._nonPrimaryButtons_ctghw_6.disabled span {
    color: #939393; }

._nextButton_ctghw_19 {
  background-color: #116dff;
  border-radius: 25px;
  color: #ffffff;
  padding: 9px 28px;
  min-width: 61px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px; }
  ._nextButton_ctghw_19 span {
    font-size: 18px;
    line-height: 1.5em;
    font-weight: 400;
    font-family: Madefor; }
  ._nextButton_ctghw_19 svg {
    margin-left: 10px; }
  ._nextButton_ctghw_19:hover {
    background-color: #3885ff; }

._disabled_ctghw_14 {
  background-color: #bcbcbc;
  pointer-events: none; }

._footer_ctghw_44 {
  display: flex;
  background-color: #ffffff;
  height: 102px;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 30px 30px rgba(0, 0, 0, 0.03); }
  @media only screen and (max-height: 900px) {
    ._footer_ctghw_44 {
      height: 82px;
      padding: 0 20px;
      box-shadow: 0 5px 20px 20px rgba(0, 0, 0, 0.03); } }

._preLoader_ctghw_58.control-preloader {
  margin-right: 15px; }
  @media only screen and (min-height: 900px) {
    ._preLoader_ctghw_58.control-preloader {
      width: 21px;
      height: 42px; } }
