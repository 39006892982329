._userMessageContainer_1c32n_1 {
  position: relative; }

._userMessage_1c32n_1 {
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding: 18px 24px;
  position: relative;
  z-index: 2; }

._userMessageContent_1c32n_12 {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  position: relative; }
