:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

ul._iconList_1cdur_7 {
  list-style: none;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  ul._iconListAnimateIn_1cdur_13 {
    opacity: 1; }
  ul._iconList_1cdur_7 li {
    padding-top: 18px;
    text-align: center; }

._icon_1cdur_7 {
  width: 32px; }
  ._icon_1cdur_7 circle {
    fill: #e1e3e6; }
