._container_3h01q_1 {
  padding: 8px;
  display: flex;
  align-items: center; }

._icon_3h01q_6 {
  border-radius: 4px;
  margin-right: 18px;
  width: 72px;
  height: 72px;
  overflow: hidden;
  display: block; }

._label_3h01q_14 {
  flex: 1; }
