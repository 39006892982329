._grid_fcgwr_8 {
  display: grid;
  grid-template-areas: 'chatArea previewArea finalStepArea';
  width: 100vw;
  height: 100vh;
  position: absolute;
  transition: 2s, transform 1s cubic-bezier(0.83, 0, 0.17, 1);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%), linear-gradient(249deg, #d4ffcd -16.01%, #d2e1ff 26.11%, #fbfcff 55.34%); }
  ._grid_fcgwr_8._modalBackground_fcgwr_17 {
    background: linear-gradient(90deg, rgba(121, 124, 128, 0.3) 0%, rgba(121, 124, 128, 0.3) 48.88%, rgba(255, 255, 255, 0) 100%); }
  ._grid_fcgwr_8._withPreview_fcgwr_19 {
    width: calc( 100vw + 640px - 24px); }
  ._grid_fcgwr_8._withFinalStep_fcgwr_21 {
    transform: translateX(calc(-640px + 24px)); }

._debugButtonWrapper_fcgwr_24 {
  position: fixed;
  display: flex;
  justify-content: space-between;
  right: 640px;
  bottom: 0;
  width: 365px;
  z-index: 100; }

._chatArea_fcgwr_33 {
  grid-area: chatArea;
  opacity: 0;
  transition: opacity 1s ease-in-out; }
  ._chatArea_fcgwr_33._visible_fcgwr_37 {
    opacity: 1; }

._previewArea_fcgwr_40 {
  grid-area: previewArea; }

._finalStepArea_fcgwr_43 {
  grid-area: finalStepArea;
  opacity: 0;
  transition: opacity 1s ease-in-out; }
  ._finalStepArea_fcgwr_43._visible_fcgwr_37 {
    opacity: 1; }
