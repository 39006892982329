._chatHeader_jje4d_8 {
  display: flex;
  height: 84px;
  align-items: center;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.5);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  left: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 99;
  width: 100%; }
  ._chatHeader_jje4d_8 ._svgLogo_jje4d_23 {
    padding: 30px 30px 30px 36px; }

._divider_jje4d_26 {
  box-shadow: inset 0 -1px 0 0 rgba(223, 229, 235, 0.502); }
