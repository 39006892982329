:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._siteGeneratorPlaygroundPanel_12zea_7 ._contentWrapper_12zea_7 {
  display: flex;
  align-items: center;
  height: calc(100% - 78px); }

._siteGeneratorPlaygroundPanel_12zea_7 ._chooseButtonsContainer_12zea_12 {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center; }

._siteGeneratorPlaygroundPanel_12zea_7 ._buttonWrapper_12zea_18 {
  padding: 20px;
  height: 100%;
  width: 50%;
  background-color: #e7f0ff;
  display: flex;
  align-items: center;
  justify-content: center; }
  ._siteGeneratorPlaygroundPanel_12zea_7 ._buttonWrapper_12zea_18._sections_12zea_26 {
    background-color: #eedbf4; }

._siteGeneratorPlaygroundPanel_12zea_7 ._leftContentTab_12zea_29 {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #dfe5eb; }

._siteGeneratorPlaygroundPanel_12zea_7 ._previewPanelSection_12zea_35 {
  height: 100%;
  text-align: center;
  vertical-align: middle;
  background-color: #e1e1e1;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none; }
  ._siteGeneratorPlaygroundPanel_12zea_7 ._previewPanelSection_12zea_35::-webkit-scrollbar {
    width: 0; }

._siteGeneratorPlaygroundPanel_12zea_7 ._siteGeneratorPlaygroundFooter_12zea_46 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  height: 78px;
  border-top: 1px solid #dfe5eb; }
