:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._playgroundInputsSection_cln1t_7 ._playgroundInputsSectionTitle_cln1t_7 {
  margin: 0 16px 12px; }

._playgroundInputsSection_cln1t_7 ._userDataButtonWrapper_cln1t_10 {
  padding: 18px 14px 0;
  text-align: center; }

._fieldWrapper_cln1t_14 {
  padding: 14px 24px 18px; }

._fieldTitle_cln1t_17 {
  margin-bottom: 6px; }

._footer_cln1t_20 {
  height: 78px;
  border-top: 1px solid #dfe5eb; }

._toggleSwitchInjectors_cln1t_24 {
  display: flex;
  justify-content: space-between; }

._toggleSwitchInjector_cln1t_24 {
  width: 100px; }
