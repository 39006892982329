.darkTheme {
  --sg-message-background: #353639;
  --sg-base-modal-close-button-background-color: #ffffff1a;
  --sg-base-modal-header-divider-background-color: #ffffff1f;
  --sg-message-text-color: #ffffff;
  --sg-link-color: #aeff84;
  --sg-link-color-hover: #94d970;
  --sg-button-background: transparent;
  --sg-button-secondary-hover: #25252a80;
  --sg-button-text-color: #ffffff;
  --sg-button-border-color: #9c9ca2;
  --sparkle-icon-src: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/aiIcon_dark.svg');
  --sg-modal-card-background: #353639;
  --sg-modal-card-text-color-secondary: #9c9ca2;
  --sg-modal-card-text-color-primary: #ffffff;
  --sg-modal-card-input-background: #353639;
  --sg-modal-card-input-border-color: #9c9ca2;
  --sg-modal-card-border-color: transparent;
  --sg-modal-divider-color: #9c9ca2;
  --sg-modal-upload-logo-background: rgba(174, 255, 132, 0.1);
  --sg-modal-upload-logo-background-hover: rgba(174, 255, 132, 0.15);
  --sg-modal-upload-logo-border: #aeff84;
  --sg-modal-upload-logo-svg: #aeff84;
  --sg-modal-input-icon-color: #aeff84;
  --sg-disabled-text-color: #9c9ca2;
  --sg-background-image: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/sg-newui-background-dark.png');
  --sg-background-gradient: linear-gradient(77deg, #d5d3d300 70%, #71717163 99%),
    linear-gradient(121deg, #496d7ac9 0%, #4d6b7900 22%);
  --sg-site-preview-background: rgba(167, 165, 165, 0.1);
  --sg-site-preview-shadow: 0px 22px 67px 0px rgba(246, 255, 237, 0.47);
  --sg-site-preview-decor-svg: #9c9ca2;
  --sg-site-preview-device-selected-background: #515254;
  --sg-site-preview-device-selected: #aeff84;
  --sg-site-preview-device-not-selected: #9c9ca2;
  --sg-checkbox-background: #aeff84;
  --sg-checkbox-hover: #aeff8426;
  --sg-dropdown-selected: #25252a;
  --sg-checkbox-background-disabled: #9c9ca2;
  --sg-checkbox-border-disabled: #9c9ca2;
  --sg-checkbox-icon-color: #000000;
  --sg-checkbox-icon-color-disabled: #cfd1dc;
  --sg-dots-loader-background-color: #25282a;
  --sg-dots-loader-dot-background-color: #999ca2;
  --sg-modal-footer-primary-button-background: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-background-hover: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-text: #000000;
  --sg-modal-footer-secondary-button-text: #ffffff;
  --sg-modal-footer-secondary-button-text-hover: #ffffffbf;
  --sg-wix-logo-colors: #ffffff;
  --sg-final-step-divider: #9c9ca2;
  --sg-final-step-input-border: #aeff84;
  --sg-final-step-input-background: #000000;
  --sg-forms-modal-background: linear-gradient(
      131deg,
      #5f8ea0b3 1%,
      #5f8ea04a 27%,
      #00000000 69%
    ),
    linear-gradient(0deg, #17181b 1%, #17181b 99%);
  --sg-modal-backdrop: rgba(0, 0, 0, 0.4);
  --sg-modal-card-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.2);
  --sg-tooltio-background: #25252a;
  --sg-tooltip-text-color: #ffffff; }

._chatFooter_1p18g_75 {
  margin-left: 48px;
  margin-top: auto;
  margin-bottom: 60px;
  box-sizing: border-box;
  transition: opacity 0.3s ease-in-out;
  opacity: 0; }
  ._chatFooter_1p18g_75._visible_1p18g_82 {
    opacity: 1; }
  ._chatFooter_1p18g_75 ._continueButton_1p18g_84 {
    color: var(--sg-modal-footer-primary-button-text, #ffffff);
    background: var(--sg-modal-footer-primary-button-background, linear-gradient(60deg, #116dff 0%, #116dff 10.81%, #116dff 59.39%, #2995e8 74.74%, #5ff2ae 99.69%));
    background-size: 200% 100%;
    background-position: right;
    transition: background-position 0.5s; }
    ._chatFooter_1p18g_75 ._continueButton_1p18g_84:hover {
      color: var(--sg-modal-footer-primary-button-text, #ffffff) !important;
      background-position: left; }

.darkTheme ._chatFooter_1p18g_75 ._designLaterButton_1p18g_94 {
  color: var(--sg-modal-footer-secondary-button-text, #116dff); }
  .darkTheme ._chatFooter_1p18g_75 ._designLaterButton_1p18g_94:hover {
    color: var(--sg-modal-footer-secondary-button-text-hover, #5999ff); }

.darkTheme ._chatFooter_1p18g_75 ._continueButton_1p18g_84 {
  color: var(--sg-modal-footer-primary-button-text, #ffffff);
  background: var(--sg-modal-footer-primary-button-background, linear-gradient(60deg, #116dff 0%, #116dff 10.81%, #116dff 59.39%, #2995e8 74.74%, #5ff2ae 99.69%));
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.5s;
  position: relative;
  transform: translate(0);
  transform-style: preserve-3d; }
  .darkTheme ._chatFooter_1p18g_75 ._continueButton_1p18g_84:hover {
    color: var(--sg-modal-footer-primary-button-text, #ffffff) !important;
    background-position: bottom; }
  .darkTheme ._chatFooter_1p18g_75 ._continueButton_1p18g_84::before {
    content: '';
    position: absolute;
    inset: 0;
    transform: translate3d(0px, 4px, -1px);
    background: linear-gradient(149.67deg, #ffffff 38.33%, #aeff84 111.49%);
    filter: blur(6px);
    border-radius: inherit; }
