:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._leftBar_bnmij_7 {
  width: 60px;
  background: #fff;
  border-top: 1px solid #dfe5eb;
  z-index: 4;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: relative; }
  ._leftBarAnimateIn_bnmij_15 {
    opacity: 1; }
  ._leftBar_bnmij_7:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-clip-path: inset(0 -20px 0 0);
            clip-path: inset(0 -20px 0 0);
    width: 100%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
    pointer-events: none; }
