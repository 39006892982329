@font-face {
  font-family: "WixMadeforText_W_XBd";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_XBd";
  font-weight: 400;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_XBd";
  font-weight: 700;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_XBd";
  font-weight: 800;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_Bd";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_Bd";
  font-weight: 400;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_Bd";
  font-weight: 700;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_Bd";
  font-weight: 800;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_Rg";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_Rg";
  font-weight: 400;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_Rg";
  font-weight: 700;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "WixMadeforText_W_Rg";
  font-weight: 800;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-45Ligh";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-55Roma";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  font-weight: 700;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-65Medi";
  font-weight: 600;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-75Bold";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-UltLt";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-Thin";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW01-95Blac";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-45Ligh";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-55Roma";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-65Medi";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-65Medi";
  font-weight: 700;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-65Medi";
  font-weight: 600;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-75Bold";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-UltLt";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-Thin";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW02-95Blac";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-45Ligh";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-55Roma";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-65Medi";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-65Medi";
  font-weight: 700;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-65Medi";
  font-weight: 600;
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-75Bold";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-25UltL";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-95Blac";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW10-35Thin";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }

@font-face {
  font-family: "HelveticaNeueW31";
  src: local(Helvetica Neue), local(HelveticaNeue), local(Helvetica), local(Arial); }
