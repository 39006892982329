._updateSectionsPanel_63ga8_1 {
  display: flex;
  flex-direction: column; }
  ._updateSectionsPanel_63ga8_1 ._content_63ga8_4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }

._preloader_63ga8_10 {
  position: absolute;
  top: 50%;
  right: 50%; }
