:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._save-preset-to-db-panel_gi77n_7 {
  display: block;
  height: calc(100vh - 30px);
  max-height: calc(100vh - 30px);
  min-width: 585px;
  margin-top: 0; }
  ._save-preset-to-db-panel_gi77n_7 ._preloader-wrapper_gi77n_13 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc( calc(100vh - 30px) - 54px - 78px - 10px); }
  ._save-preset-to-db-panel_gi77n_7 ._thumbnail-title_gi77n_18 {
    padding-bottom: 15px;
    position: relative; }
    ._save-preset-to-db-panel_gi77n_7 ._thumbnail-title_gi77n_18 .control-switch {
      padding-right: 15px; }
    ._save-preset-to-db-panel_gi77n_7 ._thumbnail-title_gi77n_18 button._reload-button_gi77n_23 {
      position: absolute;
      right: 0; }
      ._save-preset-to-db-panel_gi77n_7 ._thumbnail-title_gi77n_18 button._reload-button_gi77n_23 span {
        cursor: pointer; }
  ._save-preset-to-db-panel_gi77n_7 .control-slider-input {
    position: absolute;
    bottom: 15px;
    width: calc(100% - 40px); }
  ._save-preset-to-db-panel_gi77n_7 ._tags_gi77n_32 .control-label {
    margin-bottom: 5px; }
  ._save-preset-to-db-panel_gi77n_7 ._tags_gi77n_32 .control-checkbox {
    padding: 6px 0; }
  ._save-preset-to-db-panel_gi77n_7 ._video_gi77n_36 {
    padding: 14px 0 12px 24px; }
    ._save-preset-to-db-panel_gi77n_7 ._video_gi77n_36 .control-label {
      margin-bottom: 5px; }
    ._save-preset-to-db-panel_gi77n_7 ._video_gi77n_36 .control-checkbox {
      padding: 6px 0; }
  ._save-preset-to-db-panel_gi77n_7 ._language_gi77n_42 ._preset-language_gi77n_42 {
    margin-top: 6px; }
  ._save-preset-to-db-panel_gi77n_7 ._flex-container_gi77n_44 {
    display: flex;
    justify-content: space-between;
    height: calc( calc(100vh - 30px) - 54px - 78px - 10px); }
    ._save-preset-to-db-panel_gi77n_7 ._flex-container_gi77n_44 ._left-items_gi77n_48 {
      width: 272px;
      position: relative;
      border-right: 1px solid #dfe5eb;
      padding: 15px 20px 0;
      height: calc(calc( calc(100vh - 30px) - 54px - 78px - 10px) - 15px); }
      ._save-preset-to-db-panel_gi77n_7 ._flex-container_gi77n_44 ._left-items_gi77n_48 ._custom-scroll_gi77n_54 ._content-wrapper_gi77n_54 {
        height: calc(calc( calc(100vh - 30px) - 54px - 78px - 10px) - 110px); }
    ._save-preset-to-db-panel_gi77n_7 ._flex-container_gi77n_44 ._right-items_gi77n_56 {
      width: 272px;
      height: calc( calc(100vh - 30px) - 54px - 78px - 10px); }
      ._save-preset-to-db-panel_gi77n_7 ._flex-container_gi77n_44 ._right-items_gi77n_56 ._custom-scroll_gi77n_54 ._content-wrapper_gi77n_54 {
        height: calc( calc(100vh - 30px) - 54px - 78px - 10px); }
    ._save-preset-to-db-panel_gi77n_7 ._flex-container_gi77n_44 ._box-content_gi77n_61 {
      padding: 15px 22px; }
  ._save-preset-to-db-panel_gi77n_7 ._save-preset-footer_gi77n_63 .composite-action-set-horizontal {
    justify-content: space-between; }
    ._save-preset-to-db-panel_gi77n_7 ._save-preset-footer_gi77n_63 .composite-action-set-horizontal .control-button {
      height: 30px; }
    ._save-preset-to-db-panel_gi77n_7 ._save-preset-footer_gi77n_63 .composite-action-set-horizontal ._secondary_gi77n_67 {
      background-color: #e7f0ff;
      margin-left: 0; }
      ._save-preset-to-db-panel_gi77n_7 ._save-preset-footer_gi77n_63 .composite-action-set-horizontal ._secondary_gi77n_67:hover {
        background-color: #d6e6fe; }
