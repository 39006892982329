._kitThumbnail_13hba_1 {
  width: 242px;
  height: 146px;
  display: block;
  position: relative;
  overflow: visible;
  margin-bottom: 24px;
  border-radius: 4px; }
  ._kitThumbnail_13hba_1.selected {
    box-shadow: 0 0 0 3px #116dff; }
  @media only screen and (max-height: 608px) {
    ._kitThumbnail_13hba_1 {
      margin-bottom: calc(24px - (608px - 100vh) / 2); } }

._thumbnailBg_13hba_15 {
  width: 240px;
  height: 144px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px solid #eff1f2;
  border-radius: 4px;
  background-position: center; }

._thumbnailBox_13hba_26 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 60px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent; }

._defaultText_13hba_40 {
  font-family: Madefor, Helvetica Neue, sans-serif;
  font-size: 32px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 10px;
  line-height: 60px;
  transform: translateX(0);
  transition: transform 0.2s linear 0.05s; }

._kitSelectedV_13hba_52 {
  position: absolute;
  right: -13px;
  top: -12px;
  z-index: 1; }

._thumbnail-hover_13hba_58 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; }
  ._thumbnail-hover_13hba_58 .thumbnail-hover-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s linear 0.05s; }
  ._thumbnail-hover_13hba_58 .thumbnail-hover-text {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    padding-left: 33px;
    padding-right: 51px;
    align-items: center;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 14px;
    line-height: 1.5;
    transition: all 0.2s linear;
    opacity: 0;
    transform: translateX(18px);
    cursor: pointer; }
  ._thumbnail-hover_13hba_58 .thumbnail-color-palette {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 18px;
    height: 100%;
    top: 0;
    right: 0;
    transform: translateX(18px);
    transition: transform 0.2s linear; }
    ._thumbnail-hover_13hba_58 .thumbnail-color-palette .color-element {
      width: 100%;
      flex: 1;
      height: 100%; }
  ._thumbnail-hover_13hba_58:hover .thumbnail-bg {
    transform: scale(1.05);
    transition: transform 0.2s; }
  ._thumbnail-hover_13hba_58:hover .default-text {
    transform: translateX(-18px);
    transition: transform 0.2s linear 0.05s; }
  ._thumbnail-hover_13hba_58:hover .thumbnail-hover-bg {
    opacity: 1;
    transition: opacity 0.2s linear; }
  ._thumbnail-hover_13hba_58:hover .thumbnail-color-palette {
    transform: translateX(0);
    transition: transform 0.2s linear 0.05s; }
  ._thumbnail-hover_13hba_58:hover .thumbnail-hover-text {
    opacity: 1;
    transition: all 0.2s linear 0.05s;
    transform: translateX(0); }
