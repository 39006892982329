._chatFooter_kgv29_1 {
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 12px; }

._dividerFooter_kgv29_13 {
  left: 0;
  position: absolute; }

._input-wrapper_kgv29_17 {
  border: 1px solid #116dff;
  border-radius: 20px;
  position: relative; }
  ._input-wrapper_kgv29_17._disabled_kgv29_21 {
    border-color: #bcbcbc; }

._chat-input_kgv29_24 {
  background: 0 0;
  border: 0;
  cursor: auto;
  font-size: 16px;
  line-height: 24px;
  margin: auto;
  max-height: 78px;
  outline: 0;
  padding: 6px 0;
  resize: none;
  width: 100%; }
  ._chat-input_kgv29_24::placeholder {
    color: #acafc4; }
  ._chat-input_kgv29_24::-webkit-scrollbar {
    width: 0;
    background: transparent; }

._chat-input-wrapper_kgv29_42 {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  height: auto;
  margin: 0;
  max-height: 96px;
  min-height: 48px;
  min-width: 20px;
  overflow: hidden;
  padding: 2px 81px 2px 18px;
  width: calc(100% - 99px); }

._send-button_kgv29_55 {
  align-items: center;
  background: linear-gradient(90deg, #6f56f9 0%, #116dff 25%, #116dff 46.88%, #28d8ed 100%);
  border-radius: 15px;
  bottom: 6px;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 9px 8px 9px 10px;
  position: absolute;
  right: 6px;
  width: 40px; }
  ._send-button_kgv29_55._disabled_kgv29_21 {
    background: #bcbcbc; }
  ._send-button_kgv29_55._inactive_kgv29_69 {
    opacity: 20%; }
  ._send-button_kgv29_55 svg {
    height: 14px;
    width: 14px; }

._suggested-inputs_kgv29_75 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap; }

._chip_kgv29_81 {
  display: flex;
  border-radius: 12px;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: #116dff;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  ._chip_kgv29_81._suggestion-chip_kgv29_93 {
    padding: 6px 18px 6px 18px !important;
    background-color: #e7f0ff !important; }
  ._chip_kgv29_81._suggestion-chip-disabled_kgv29_96 {
    padding: 6px 18px 6px 18px !important;
    background-color: #f0f0f0 !important;
    color: #808080;
    cursor: default; }

._back-to-brief-chip_kgv29_102 {
  padding: 6px 18px 6px 12px !important;
  border: 1px solid #dfe5eb !important;
  background-color: #ffffff !important; }
  ._back-to-brief-chip_kgv29_102._disabled_kgv29_21 {
    background-color: #f0f0f0 !important;
    color: #808080;
    cursor: default; }

._prefix-icon_kgv29_111 {
  display: flex;
  margin-right: 4px;
  justify-content: center;
  align-items: center; }

._buttonFooter_kgv29_117 {
  padding: 30px;
  padding-right: 10px;
  align-self: flex-end; }
