:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel_an1is_7 {
  display: block; }

._title_an1is_10 {
  padding: 18px 24px 6px 24px; }

._wrapper_an1is_13 {
  padding: 6px 0 18px 0; }

._action-item_an1is_16 {
  block-size: 42px !important;
  -webkit-padding-before: 9px !important;
          padding-block-start: 9px !important;
  -webkit-padding-after: 9px !important;
          padding-block-end: 9px !important; }
  ._action-item_an1is_16 .info .illustration-wrapper {
    -webkit-margin-end: 12px !important;
            margin-inline-end: 12px !important; }
    ._action-item_an1is_16 .info .illustration-wrapper svg,
    ._action-item_an1is_16 .info .illustration-wrapper svg g {
      fill: #333853; }
