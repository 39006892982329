._chat_f6qvo_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  ._chat__messages_f6qvo_5 {
    padding: 12px 18px 32px 18px;
    height: 234.5px;
    background: #edf1f5;
    overflow-y: auto;
    scrollbar-gutter: stable;
    position: relative; }
  ._chat__inputWrapper_f6qvo_12 {
    background: #edf1f5;
    z-index: 1; }
  ._chat__input_f6qvo_12 {
    padding: 18px 18px 0 18px;
    border-radius: 8px 8px 0px 0px;
    background: white;
    box-shadow: 0px -2px 2px -1px rgba(0, 6, 36, 0.12);
    font-size: 14px; }
  ._chat__preloader_f6qvo_21 {
    position: absolute;
    left: 50%;
    bottom: 50%;
    margin-bottom: -60px; }
  ._chat__suggestions_f6qvo_26 {
    padding: 12px 18px 18px 18px; }
  ._chat__suggestions_improveTextMode_f6qvo_28 {
    padding-top: 6px;
    padding-bottom: 12px; }
  ._chat__errorMessage_f6qvo_31 > * {
    vertical-align: bottom; }
  ._chat__termsAndConditions_f6qvo_33 {
    position: absolute;
    left: 0;
    bottom: 0; }
