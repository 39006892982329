._progress_1ykyb_1 {
  height: 3px;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out; }
  ._progressAnimateIn_1ykyb_8 {
    opacity: 1; }
    ._progressAnimateIn_1ykyb_8:before {
      display: block;
      content: ' ';
      height: 100%;
      background-color: #116dff;
      animation: _fakeProgressAnimation_1ykyb_1 calc(var(--animation-duration) * 1s) forwards; }

@keyframes _fakeProgressAnimation_1ykyb_1 {
  0% {
    transform: translate3d(-100%, 0, 0); }
  10% {
    transform: translate3d(-50%, 0, 0); }
  30% {
    transform: translate3d(-20%, 0, 0); }
  50% {
    transform: translate3d(-15%, 0, 0); }
  100% {
    transform: translate3d(-10%, 0, 0); } }
