._button_irzr6_1, ._buttonEdit_irzr6_1 {
  position: absolute;
  right: -1px;
  bottom: -7px;
  width: 30px;
  height: 30px;
  padding: 0;
  background: #ffffff;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 1px 20px 0 #00000014, 0 2px 10px 0 #00000014, 0 1px 5px 0 #0000001f;
  cursor: pointer; }
  ._button_irzr6_1 circle, ._buttonEdit_irzr6_1 circle {
    fill: #ffffff; }
  ._button_irzr6_1 path, ._buttonEdit_irzr6_1 path {
    fill: #116dff; }
  ._button_irzr6_1:hover circle, ._buttonEdit_irzr6_1:hover circle {
    fill: #d6e6ff; }

._root_irzr6_20 {
  position: relative;
  display: inline-block; }

._fileInvalidIcon_irzr6_24 {
  height: 24px;
  width: 24px; }

._errorNotification_irzr6_28 {
  display: flex;
  align-items: center;
  margin-top: 23px;
  margin-bottom: -12px;
  max-width: 300px; }
  ._errorNotification_irzr6_28 ._errorText_irzr6_34 {
    position: relative;
    width: 100%;
    margin-left: 8px;
    font-size: 14px;
    line-height: 1.3em;
    color: #fc3d2f;
    font-weight: 400; }

._fileInput_irzr6_43 {
  display: none; }

._contentPlaceholder_irzr6_46 {
  position: relative;
  width: 75px;
  height: 75px;
  background-color: #424851;
  border: 1px solid #e1e3e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  ._contentPlaceholder_irzr6_46 span {
    font-size: 10px;
    line-height: 1.4em;
    color: #fff;
    opacity: 0.5;
    font-weight: 700;
    width: -webkit-min-content;
    width: min-content;
    text-align: center; }

._contentWrapper_irzr6_66 {
  position: relative;
  box-sizing: border-box;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border: 1px solid #e0e3e5;
  border-radius: 50%; }

._image_irzr6_75 {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #eff1f2;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  cursor: pointer; }

._buttonEdit_irzr6_1 svg {
  margin-bottom: 1px;
  margin-left: 2px; }

._buttonEdit_irzr6_1:focus ._menu_irzr6_89 {
  transform: translateY(0);
  visibility: visible;
  opacity: 1; }

._buttonEdit_irzr6_1:hover {
  background-color: #d6e6ff; }

._menu_irzr6_89 {
  position: absolute;
  top: 0;
  z-index: 2;
  margin: 0;
  padding: 17px 13px;
  background: #ffffff;
  border-radius: 2px;
  transform: translateY(-5px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.15s ease;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 2px 10px rgba(0, 0, 0, 0.08), 0 1px 20px rgba(0, 0, 0, 0.08); }

._menuItem_irzr6_111 {
  display: flex;
  align-items: center;
  height: 18px;
  font-size: 12px;
  line-height: 1.5em;
  color: #17191c;
  cursor: pointer; }
  ._menuItem_irzr6_111 ._icon_irzr6_119 {
    height: 18px;
    width: 18px;
    text-align: center;
    margin-right: 8px; }
    ._menuItem_irzr6_111 ._icon_irzr6_119 path {
      fill: #17191c; }
  ._menuItem_irzr6_111:hover {
    color: #116dff; }
    ._menuItem_irzr6_111:hover path {
      fill: #116dff; }
  ._menuItem_irzr6_111:not(:last-child) {
    margin-bottom: 16px; }
  ._menuItem_irzr6_111 > svg {
    margin-right: 8px; }
  ._menuItem_irzr6_111 > ._menuItemText_irzr6_134 {
    white-space: nowrap; }

._loaderWrap_irzr6_137 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #eff1f2; }

._loader_irzr6_137 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  color: #c2c7cb;
  transform: translate(-50%, -50%); }
  ._loader_irzr6_137 > svg {
    animation: _spin_irzr6_1 1s linear infinite; }
