._fieldWrapper_1om66_1 {
  padding: 14px 24px 18px; }

._businessCardContent_1om66_4 {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px; }

._colorsFromLogoToggleContainer_1om66_9 {
  width: 70%;
  margin-top: 10px; }
