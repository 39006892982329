.darkTheme {
  --sg-message-background: #353639;
  --sg-base-modal-close-button-background-color: #ffffff1a;
  --sg-base-modal-header-divider-background-color: #ffffff1f;
  --sg-message-text-color: #ffffff;
  --sg-link-color: #aeff84;
  --sg-link-color-hover: #94d970;
  --sg-button-background: transparent;
  --sg-button-secondary-hover: #25252a80;
  --sg-button-text-color: #ffffff;
  --sg-button-border-color: #9c9ca2;
  --sparkle-icon-src: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/aiIcon_dark.svg');
  --sg-modal-card-background: #353639;
  --sg-modal-card-text-color-secondary: #9c9ca2;
  --sg-modal-card-text-color-primary: #ffffff;
  --sg-modal-card-input-background: #353639;
  --sg-modal-card-input-border-color: #9c9ca2;
  --sg-modal-card-border-color: transparent;
  --sg-modal-divider-color: #9c9ca2;
  --sg-modal-upload-logo-background: rgba(174, 255, 132, 0.1);
  --sg-modal-upload-logo-background-hover: rgba(174, 255, 132, 0.15);
  --sg-modal-upload-logo-border: #aeff84;
  --sg-modal-upload-logo-svg: #aeff84;
  --sg-modal-input-icon-color: #aeff84;
  --sg-disabled-text-color: #9c9ca2;
  --sg-background-image: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/sg-newui-background-dark.png');
  --sg-background-gradient: linear-gradient(77deg, #d5d3d300 70%, #71717163 99%),
    linear-gradient(121deg, #496d7ac9 0%, #4d6b7900 22%);
  --sg-site-preview-background: rgba(167, 165, 165, 0.1);
  --sg-site-preview-shadow: 0px 22px 67px 0px rgba(246, 255, 237, 0.47);
  --sg-site-preview-decor-svg: #9c9ca2;
  --sg-site-preview-device-selected-background: #515254;
  --sg-site-preview-device-selected: #aeff84;
  --sg-site-preview-device-not-selected: #9c9ca2;
  --sg-checkbox-background: #aeff84;
  --sg-checkbox-hover: #aeff8426;
  --sg-dropdown-selected: #25252a;
  --sg-checkbox-background-disabled: #9c9ca2;
  --sg-checkbox-border-disabled: #9c9ca2;
  --sg-checkbox-icon-color: #000000;
  --sg-checkbox-icon-color-disabled: #cfd1dc;
  --sg-dots-loader-background-color: #25282a;
  --sg-dots-loader-dot-background-color: #999ca2;
  --sg-modal-footer-primary-button-background: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-background-hover: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-text: #000000;
  --sg-modal-footer-secondary-button-text: #ffffff;
  --sg-modal-footer-secondary-button-text-hover: #ffffffbf;
  --sg-wix-logo-colors: #ffffff;
  --sg-final-step-divider: #9c9ca2;
  --sg-final-step-input-border: #aeff84;
  --sg-final-step-input-background: #000000;
  --sg-forms-modal-background: linear-gradient(
      131deg,
      #5f8ea0b3 1%,
      #5f8ea04a 27%,
      #00000000 69%
    ),
    linear-gradient(0deg, #17181b 1%, #17181b 99%);
  --sg-modal-backdrop: rgba(0, 0, 0, 0.4);
  --sg-modal-card-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.2);
  --sg-tooltio-background: #25252a;
  --sg-tooltip-text-color: #ffffff; }

._mainWrapper_5wf04_75 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: transform 1s cubic-bezier(0.83, 0, 0.17, 1); }
  ._mainWrapper_5wf04_75 ._bg_5wf04_79 {
    position: absolute;
    inset: 0;
    z-index: -1; }
  ._mainWrapper_5wf04_75._showFinalStep_5wf04_83 {
    transform: translate(calc(-1 * clamp(480px, 33vw, 680px)), 0); }
  ._mainWrapper_5wf04_75 ._mainGrid_5wf04_85 {
    width: calc(100vw + clamp(480px, 33vw, 680px));
    height: 100vh;
    display: grid;
    grid-template-columns: clamp(480px, 33vw, 680px) calc(100vw - clamp(480px, 33vw, 680px)) clamp(480px, 33vw, 680px); }
    ._mainWrapper_5wf04_75 ._mainGrid_5wf04_85 ._gridCell_5wf04_90 {
      height: 100vh;
      display: flex;
      flex-direction: column; }
      ._mainWrapper_5wf04_75 ._mainGrid_5wf04_85 ._gridCell_5wf04_90._preview_5wf04_94 {
        justify-content: center;
        align-items: center; }

._modalWrapper_5wf04_98 {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background: var(--sg-modal-backdrop, linear-gradient(90deg, rgba(148, 158, 175, 0.3) 0%, rgba(255, 255, 255, 0) 100%));
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 500ms ease-out;
  width: 100%;
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0; }
  ._modalWrapper_5wf04_98._visible_5wf04_113 {
    opacity: 1; }

.darkTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 {
  box-shadow: 0px 10px 100px 0px #669ea166;
  background: var(--sg-forms-modal-background, #ffffff) padding-box, linear-gradient(62.09deg, #6c939f 15.65%, #758ba0 39.02%, #577b7c 63.68%) border-box;
  border-radius: 20px;
  border: 1px solid transparent; }
  .darkTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119._formsBaseModal_5wf04_124 [data-hook='baseModalLayout-content-wrapper']::-webkit-scrollbar-thumb {
    background-color: var(--sg-modal-card-input-border-color, #d6e6fe); }
    .darkTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119._formsBaseModal_5wf04_124 [data-hook='baseModalLayout-content-wrapper']::-webkit-scrollbar-thumb:hover {
      background-color: var(--sg-modal-card-input-border-color, #d6e6fe); }
    .darkTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119._formsBaseModal_5wf04_124 [data-hook='baseModalLayout-content-wrapper']::-webkit-scrollbar-thumb:active {
      background-color: var(--sg-modal-card-input-border-color, #d6e6fe); }

._modalWrapper_5wf04_98 ._baseModal_5wf04_119 {
  position: absolute;
  border-radius: 12px;
  border: 1px;
  top: 12vh;
  height: 76vh;
  left: 48px;
  max-width: calc(clamp(480px, 33vw, 680px) - 48px);
  min-width: auto;
  width: 100%; }
  ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 ._baseModalTitle_5wf04_141 {
    color: var(--sg-message-text-color, #000624) !important;
    font-weight: 800 !important;
    line-height: 24px !important;
    position: relative;
    z-index: 1; }
  ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 div hr {
    background-color: var(--sg-base-modal-header-divider-background-color, #dfe5eb); }
  ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 [data-hook='baseModalLayout-close-button'] {
    color: var(--sg-message-text-color, #000624);
    background-color: var(--sg-base-modal-close-button-background-color, #ffffff1a);
    border-radius: 30px;
    width: 24px;
    height: 24px;
    padding: 3px;
    margin-top: 5px; }
  ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 [data-hook='baseModalLayout-secondary-button'] {
    background: transparent !important;
    outline: none;
    color: var(--sg-modal-footer-secondary-button-text, #116dff) !important; }
    ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 [data-hook='baseModalLayout-secondary-button']:hover {
      color: var(--sg-modal-footer-secondary-button-text-hover, #5999ff) !important; }
  ._modalWrapper_5wf04_98 ._baseModal_5wf04_119._chatBaseModal_5wf04_163 [data-hook='baseModalLayout-content-wrapper'] {
    padding: 0 0 18px !important; }

.darkTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 [data-hook='baseModalLayout-primary-button'] {
  color: var(--sg-modal-footer-primary-button-text, #ffffff);
  background: var(--sg-modal-footer-primary-button-background, linear-gradient(60deg, #116dff 0%, #116dff 10.81%, #116dff 59.39%, #2995e8 74.74%, #5ff2ae 99.69%));
  background-size: 100% 200%;
  background-position: top;
  transition: background-position 0.5s; }
  .darkTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 [data-hook='baseModalLayout-primary-button']:hover {
    color: var(--sg-modal-footer-primary-button-text, #ffffff) !important;
    background-position: bottom; }

.darkTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 [data-hook='baseModalLayout-close-button']:hover {
  color: var(--sg-modal-card-text-color-secondary, #333853) !important; }

.lightTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 [data-hook='baseModalLayout-primary-button'] {
  color: var(--sg-modal-footer-primary-button-text, #ffffff);
  background: var(--sg-modal-footer-primary-button-background, linear-gradient(60deg, #116dff 0%, #116dff 10.81%, #116dff 59.39%, #2995e8 74.74%, #5ff2ae 99.69%));
  background-size: 200% 100%;
  background-position: right;
  transition: background-position 0.5s; }
  .lightTheme ._modalWrapper_5wf04_98 ._baseModal_5wf04_119 [data-hook='baseModalLayout-primary-button']:hover {
    color: var(--sg-modal-footer-primary-button-text, #ffffff) !important;
    background-position: left; }
