._baseAccordion_7687t_1 {
  width: 100%; }

._accordionHeader_7687t_4 {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px; }

._accordionTitle_7687t_12 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000624; }

._accordionIcon_7687t_18 {
  width: 24px;
  height: 24px;
  margin-right: 12px; }
  ._accordionIcon_7687t_18._reverseIcon_7687t_22 {
    transform: rotate(180deg); }

._accordionContent_7687t_25 {
  padding-top: 18px;
  padding-left: 36px;
  padding-right: 36px; }
