:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 {
  display: flex;
  align-items: center;
  height: calc(100% - 78px); }
  ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 {
    height: 100%;
    display: flex;
    flex-direction: column; }
    ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 ._presetPreviewSection_geqw1_15 {
      position: relative;
      min-height: 50px;
      overflow: auto; }
      ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 ._presetPreviewSection_geqw1_15 ._previewDeviceChangeWrapper_geqw1_19 {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex; }
        ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 ._presetPreviewSection_geqw1_15 ._previewDeviceChangeWrapper_geqw1_19 ._previewDeviceChange_geqw1_19 {
          cursor: pointer; }
          ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 ._presetPreviewSection_geqw1_15 ._previewDeviceChangeWrapper_geqw1_19 ._previewDeviceChange_geqw1_19._deviceChangeSelected_geqw1_26 svg path {
            fill: #116dff; }
          ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 ._presetPreviewSection_geqw1_15 ._previewDeviceChangeWrapper_geqw1_19 ._previewDeviceChange_geqw1_19:hover svg path {
            fill: #116dff; }
      ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 ._presetPreviewSection_geqw1_15 ._presetPreviewWrapper_geqw1_30 {
        display: block; }
      ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 ._presetPreviewSection_geqw1_15._hasPreview_geqw1_32 {
        border: 1px solid #dfe5eb;
        border-radius: 4px; }
      ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._leftContentTab_geqw1_11 ._presetPreviewSection_geqw1_15 .control-preloader {
        margin: auto; }
  ._designersScanPresetPanel_geqw1_7 ._contentWrapper_geqw1_7 ._rightContentTab_geqw1_37 {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    border-left: 1px solid #dfe5eb; }

._designersScanPresetPanel_geqw1_7 ._savePresetFooter_geqw1_43 {
  height: 78px;
  border-top: 1px solid #dfe5eb; }
  ._designersScanPresetPanel_geqw1_7 ._savePresetFooter_geqw1_43 .composite-action-set-horizontal {
    justify-content: space-between; }
    ._designersScanPresetPanel_geqw1_7 ._savePresetFooter_geqw1_43 .composite-action-set-horizontal .control-button {
      height: 30px; }
    ._designersScanPresetPanel_geqw1_7 ._savePresetFooter_geqw1_43 .composite-action-set-horizontal .control-button.secondary {
      background-color: #e7f0ff;
      margin-left: 0; }
      ._designersScanPresetPanel_geqw1_7 ._savePresetFooter_geqw1_43 .composite-action-set-horizontal .control-button.secondary:hover {
        background-color: #d6e6fe; }
