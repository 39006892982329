._container_1i8cs_1 {
  display: grid;
  grid-template-rows: calc(100% - 76px) 76px;
  grid-template-columns: 100%;
  grid-template-areas: 'modalStructureContainer' 'modalCtaContainer';
  height: 100%;
  width: 100%; }

._modalStructureContainer_1i8cs_9 {
  grid-area: modalStructureContainer;
  padding: 24px;
  overflow-y: auto;
  border-bottom-width: 1px;
  border-bottom-color: #dfe5eb;
  border-bottom-style: solid; }

._structureTitle_1i8cs_17 {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px; }

._checkBoxPageItem_1i8cs_22,
._checkBoxSectionItem_1i8cs_23 {
  margin-bottom: 18px; }

._modalCtaContainer_1i8cs_26 {
  grid-area: modalCtaContainer;
  padding: 24px;
  display: flex;
  justify-content: flex-end; }
  ._modalCtaContainer_1i8cs_26 > *:first-child {
    margin-right: 12px; }

._checkboxSectionContainer_1i8cs_34 {
  padding-left: 30px; }
