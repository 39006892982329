._wrapper_1exbf_1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: -webkit-max-content;
  width: max-content;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

._gifContainer_1exbf_13 {
  background-position: center;
  background-size: contain;
  width: 150px;
  height: 150px;
  margin-bottom: 12px;
  background-image: url("https://static.parastorage.com/services/santa-resources/dist/editor/preloader/heavySites/loader_general.gif"); }

._titlesContainer_1exbf_21 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px; }

._mainTitle_1exbf_28 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000624; }

._secondaryTitle_1exbf_34 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #3b4057; }
