._tooltip_1sn8f_1 {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px; }

._linkButton_1sn8f_7 {
  color: #3899ec; }
  ._linkButton_1sn8f_7:hover {
    text-decoration: underline; }
