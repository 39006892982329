._container_z4hcc_1 {
  margin: 6px 0 30px; }

._headerSection_z4hcc_4 {
  padding: 12px 24px 24px 24px; }

._previewSection_z4hcc_7 {
  padding: 0px 24px 24px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white; }

._colorSelectLabeled_z4hcc_14 {
  position: relative; }

._accessibilityIndicator_z4hcc_17 {
  position: absolute;
  top: 8px;
  right: 20px;
  height: 8px;
  width: 8px;
  background-color: #e62214;
  border: 1px solid white;
  border-radius: 50%; }
