@keyframes _smoothAppear_101ev_1 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._form_101ev_7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }

._textField_101ev_13 {
  width: 100%;
  padding-bottom: 12px;
  max-height: 108px; }

._formControlsContainer_101ev_18 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center; }

._palettesControlls_101ev_24 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px; }
  ._palettesControlls_101ev_24._animate_101ev_30 {
    animation-name: _smoothAppear_101ev_1;
    animation-duration: 1.5s; }

._textButton_101ev_34 {
  display: flex;
  justify-content: space-around;
  background: aqua; }
