._generateSiteButton_8cr06_1 {
  max-height: 300px;
  margin-top: 18px; }

._hidden_8cr06_5 {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  transition: visibility 0s 1s, opacity 1s linear, max-height 1s ease-in-out; }

._loading_8cr06_11 {
  pointer-events: none;
  cursor: default;
  width: 167px; }
