:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_cl6ap_7 {
  width: 306px; }

._contactInfoInput_cl6ap_10 {
  position: relative;
  width: 100%;
  margin-bottom: 12px; }
  ._contactInfoInput_cl6ap_10 > input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #d6e6fe;
    padding: 6px 9px 6px 36px;
    font-size: 16px; }
  ._contactInfoInput_cl6ap_10 ._invalidBorder_cl6ap_21 {
    border: 1px solid #e62214; }
    ._contactInfoInput_cl6ap_10 ._invalidBorder_cl6ap_21:focus {
      outline-color: #e62214; }
  ._contactInfoInput_cl6ap_10 ._inputIcon_cl6ap_25 {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 12px;
    top: 16px;
    transform: translateY(-50%); }
  ._contactInfoInput_cl6ap_10:hover ._inputTooltip_cl6ap_32 {
    display: inline-block; }
  ._contactInfoInput_cl6ap_10 ._inputTooltip_cl6ap_32 {
    display: none;
    background-color: #000000;
    color: #fff;
    text-align: center;
    padding: 12px 24px;
    border-radius: 8px;
    position: absolute;
    bottom: calc(100% + 4px);
    right: calc(100% - 24px);
    transform: translateX(50%);
    box-shadow: 0 0 22px 0 rgba(22, 45, 61, 0.2);
    white-space: nowrap; }
    ._contactInfoInput_cl6ap_10 ._inputTooltip_cl6ap_32:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -9px;
      border-width: 9px;
      border-style: solid;
      border-color: #000000 transparent transparent transparent; }

._invalidInput_cl6ap_57 {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 6px; }
  ._invalidInput_cl6ap_57 ._invalidMessage_cl6ap_63 {
    margin-left: 20px; }
  ._invalidInput_cl6ap_57 ._invalidIcon_cl6ap_65 {
    position: absolute;
    height: 18px;
    width: 18px;
    fill: #e62214; }
    ._invalidInput_cl6ap_57 ._invalidIcon_cl6ap_65 g {
      stroke: #fff;
      stroke-width: 3px; }
