:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._scanKitPanel_136t8_7 {
  height: 85vh; }
  ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 {
    height: 100%;
    display: flex;
    flex-direction: column; }
    ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._kitMetaDataSection_136t8_13 {
      padding: 12px; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._kitMetaDataSection_136t8_13 > div {
        display: inline-block; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._kitMetaDataSection_136t8_13 ._kitId_136t8_17 {
        width: 200px; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._kitMetaDataSection_136t8_13 ._translationKeyBox_136t8_19 {
        width: 170px; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._kitMetaDataSection_136t8_13 ._gradientKit_136t8_21 {
        width: 110px; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._kitMetaDataSection_136t8_13 ._caps_136t8_23 {
        width: 200px; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._kitMetaDataSection_136t8_13 ._description_136t8_25 {
        width: 250px; }
    ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 {
      border-top: 1px solid #dfe5eb;
      flex: 1;
      overflow-y: scroll; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 > section {
        opacity: 1;
        visibility: visible;
        transition: opacity 200ms ease-in; }
        ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 > section._hidden_136t8_35 {
          opacity: 0;
          visibility: hidden;
          display: none; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._sectionElementWrapper_136t8_39 {
        padding: 24px;
        display: flex;
        justify-content: space-around;
        align-items: center; }
        ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._sectionElementWrapper_136t8_39 ._sectionPreview_136t8_44 {
          border: 1px solid #dfe5eb;
          border-radius: 4px; }
        ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._sectionElementWrapper_136t8_39 ._sectionOptionWrapper_136t8_47 ._sectionOptions_136t8_47 {
          width: 200px; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 {
        display: flex;
        justify-content: center;
        height: calc(100% - 59px); }
        ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 {
          width: 50%;
          overflow-y: scroll;
          text-align: center; }
          ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._colorationEntry_136t8_57 {
            margin-top: 6px;
            display: flex;
            justify-content: space-around;
            align-items: center; }
            ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._colorationEntry_136t8_57 ._colorationEntryWrap_136t8_62 {
              display: flex;
              justify-content: space-around;
              align-items: center; }
              ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._colorationEntry_136t8_57 ._colorationEntryWrap_136t8_62 ._colorationDropDown_136t8_66 {
                width: 150px; }
              ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._colorationEntry_136t8_57 ._colorationEntryWrap_136t8_62 ._colorationEntryColor_136t8_68 {
                width: 30px;
                height: 15px;
                border-radius: 3px;
                margin-left: 4px;
                opacity: 0;
                visibility: hidden;
                border: 1px solid #dfe5eb; }
                ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._colorationEntry_136t8_57 ._colorationEntryWrap_136t8_62 ._colorationEntryColor_136t8_68._visible_136t8_76 {
                  opacity: 1;
                  visibility: visible; }
            ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._colorationEntry_136t8_57 ._colorationEntryRemove_136t8_79 {
              cursor: pointer; }
          ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._addColoration_136t8_81 {
            margin-top: 8px;
            display: flex;
            justify-content: center;
            align-items: center; }
            ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._addColoration_136t8_81 ._addColorationButton_136t8_86 {
              margin-right: 6px; }
              ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._addColoration_136t8_81 ._addColorationButton_136t8_86 circle {
                stroke: #116dff;
                fill: #116dff; }
            ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._addColoration_136t8_81 :hover {
              cursor: pointer; }
              ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._panelContent_136t8_27 ._colorationWrapper_136t8_49 ._coloration_136t8_49 ._addColoration_136t8_81 :hover circle {
                stroke: #5999ff;
                fill: #5999ff; }
    ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._languagesSections_136t8_96 {
      padding: 12px;
      max-width: 500px; }
    ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._saveKitFooter_136t8_99 {
      width: calc(100% - 24px * 2);
      border-top: 1px solid #dfe5eb;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: flex-end; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._saveKitFooter_136t8_99 ._submitWrapper_136t8_107 {
        display: flex;
        justify-content: center;
        align-items: center; }
        ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._saveKitFooter_136t8_99 ._submitWrapper_136t8_107 ._isPublished_136t8_111 {
          width: 140px;
          display: flex;
          align-items: center; }
          ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._saveKitFooter_136t8_99 ._submitWrapper_136t8_107 ._isPublished_136t8_111 ._isPublishedCheckbox_136t8_115 {
            margin-left: 6px; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._saveKitFooter_136t8_99 .control-button {
        height: 30px; }
      ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._saveKitFooter_136t8_99 .control-button.secondary {
        background-color: #e7f0ff;
        margin-left: 0; }
        ._scanKitPanel_136t8_7 ._contentWrapper_136t8_9 ._saveKitFooter_136t8_99 .control-button.secondary:hover {
          background-color: #d6e6fe; }
