._title_1gx8w_1 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  padding-bottom: 40px;
  color: #000; }

._list_1gx8w_8 {
  width: 320px;
  text-align: left;
  padding: 0 0 78px 20px;
  margin: 0;
  list-style: none; }

._item_1gx8w_15 {
  list-style: none;
  display: block;
  position: relative;
  color: #acafc4;
  padding: 0 0 14px 42px;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500; }

._item_1gx8w_15:nth-child(1),
._item_1gx8w_15:nth-child(2) {
  color: #3b4057;
  font-weight: 500; }

._iconSuccess_1gx8w_31,
._iconLoading_1gx8w_32 {
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px; }

._iconSuccess_1gx8w_31 svg {
  fill: #116dff;
  margin: -3px 0 0 -3px; }

._iconLoading_1gx8w_32:before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid #116dff;
  box-sizing: border-box;
  border-radius: 50%;
  border-top-color: transparent;
  animation: _preloaderRotate_1gx8w_1 1s linear infinite; }

._item_1gx8w_15:nth-child(1) ._iconSuccess_1gx8w_31,
._item_1gx8w_15:nth-child(2) ._iconSuccess_1gx8w_31 {
  visibility: visible; }

._item_1gx8w_15:nth-child(3) {
  color: #3b4057;
  font-weight: 700;
  animation: 5s :local(preloaderTextActiveOut);
  animation-fill-mode: forwards; }

._item_1gx8w_15:nth-child(3) ._iconLoading_1gx8w_32 {
  visibility: visible;
  animation: 5s :local(preloaderFadeOut);
  animation-fill-mode: forwards; }

._item_1gx8w_15:nth-child(3) ._iconSuccess_1gx8w_31 {
  animation: 5s :local(preloaderFadeIn);
  animation-fill-mode: forwards; }

._item_1gx8w_15:nth-child(4) {
  animation: 6s :local(preloaderTextActiveIn);
  animation-fill-mode: forwards; }

._item_1gx8w_15:nth-child(4) ._iconLoading_1gx8w_32 {
  animation: 6s :local(preloaderFadeIn);
  animation-fill-mode: forwards; }

@keyframes _preloaderFadeIn_1gx8w_1 {
  99% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@keyframes _preloaderFadeOut_1gx8w_1 {
  99% {
    visibility: visible; }
  100% {
    visibility: hidden; } }

@keyframes _preloaderRotate_1gx8w_1 {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes _preloaderTextActiveIn_1gx8w_1 {
  99% {
    font-weight: 500;
    color: #acafc4; }
  100% {
    font-weight: 700;
    color: #3b4057; } }

@keyframes _preloaderTextActiveOut_1gx8w_1 {
  99% {
    font-weight: 700;
    color: #3b4057; }
  100% {
    font-weight: 500;
    color: #3b4057; } }
