:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._elements-root-container_a4xt0_7 {
  max-height: 521px; }
  ._elements-root-container_a4xt0_7._sections-exist_a4xt0_9 {
    height: 525px; }
  ._elements-root-container_a4xt0_7 ._elements-premium-icon_a4xt0_11 {
    visibility: visible !important; }
    ._elements-root-container_a4xt0_7 ._elements-premium-icon_a4xt0_11 path {
      fill: #9a27d5 !important; }
  ._elements-root-container_a4xt0_7 ._subtitle_a4xt0_15 {
    display: flex;
    flex-direction: column;
    padding: 24px; }
  ._elements-root-container_a4xt0_7 ._section-container_a4xt0_19 ._category-container_a4xt0_19 {
    padding: 12px 24px 18px; }
  ._elements-root-container_a4xt0_7 ._section-container_a4xt0_19 ._category-divider-container_a4xt0_21 {
    margin-bottom: 0; }
  ._elements-root-container_a4xt0_7 ._section-container_a4xt0_19 ._checkboxes-container_a4xt0_23 {
    padding: 18px 24px; }
  ._elements-root-container_a4xt0_7 ._category-container_a4xt0_19 {
    padding: 0 24px 18px; }
    ._elements-root-container_a4xt0_7 ._category-container_a4xt0_19 ._category-label_a4xt0_27 {
      max-width: 210px;
      padding-bottom: 18px; }
  ._elements-root-container_a4xt0_7 ._category-divider-container_a4xt0_21 {
    margin-bottom: 18px; }
  ._elements-root-container_a4xt0_7 ._toggle-all-button_a4xt0_32 {
    padding: 0 24px 18px; }
  ._elements-root-container_a4xt0_7 ._checkboxes-container_a4xt0_23 {
    padding: 0 24px 18px; }
  ._elements-root-container_a4xt0_7 ._checkbox-container_a4xt0_36 {
    max-width: 209px; }
  ._elements-root-container_a4xt0_7 .composite-checkboxes > .has-tooltip.info-icon-tooltip {
    visibility: hidden;
    position: absolute;
    right: 24px; }
  ._elements-root-container_a4xt0_7 .composite-checkboxes:hover > .has-tooltip.info-icon-tooltip {
    visibility: visible; }
  ._elements-root-container_a4xt0_7 .composite-with-info-icon > .has-tooltip.info-icon-tooltip {
    right: 0; }

._elements-premium-tooltip_a4xt0_47 ._premium-info-icon-link_a4xt0_47 {
  color: #9a27d5 !important; }
