._container_1gcl2_1 {
  display: flex;
  align-items: center; }

._logoImageContainer_1gcl2_5 {
  position: relative;
  width: 38px;
  height: 38px;
  border: 1px solid #e0e3e6;
  border-radius: 4px; }
  ._logoImageContainer_1gcl2_5 > ._logoImage_1gcl2_5 {
    width: 100%;
    height: 100%; }

._title_1gcl2_15 {
  margin-left: 8px;
  flex-grow: 1; }
