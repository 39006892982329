._sitePreviewViewWrapper_vxte3_8 {
  position: relative;
  overflow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }
  ._sitePreviewViewWrapper_vxte3_8 ._sitePreviewView_vxte3_8 {
    margin: 24px 24px 24px 0;
    border-radius: 30px;
    background: RGB(255, 255, 255, 0.55);
    box-shadow: 0 20px 40px 0 #202e6426;
    width: 100%;
    position: relative;
    overflow: hidden;
    border: 1px solid #ffffff;
    transition: max-width 1s ease-in-out, height 1s ease-in-out; }
  ._sitePreviewViewWrapper_vxte3_8 ._sitePreviewTopWrapper_vxte3_26 {
    height: 42px;
    width: 100%;
    background: #ffffff; }
  ._sitePreviewViewWrapper_vxte3_8 ._sitePreview_vxte3_8 {
    background: transparent;
    position: absolute;
    top: 42px;
    height: calc(100% - 42px);
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth; }
