.darkTheme {
  --sg-message-background: #353639;
  --sg-base-modal-close-button-background-color: #ffffff1a;
  --sg-base-modal-header-divider-background-color: #ffffff1f;
  --sg-message-text-color: #ffffff;
  --sg-link-color: #aeff84;
  --sg-link-color-hover: #94d970;
  --sg-button-background: transparent;
  --sg-button-secondary-hover: #25252a80;
  --sg-button-text-color: #ffffff;
  --sg-button-border-color: #9c9ca2;
  --sparkle-icon-src: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/aiIcon_dark.svg');
  --sg-modal-card-background: #353639;
  --sg-modal-card-text-color-secondary: #9c9ca2;
  --sg-modal-card-text-color-primary: #ffffff;
  --sg-modal-card-input-background: #353639;
  --sg-modal-card-input-border-color: #9c9ca2;
  --sg-modal-card-border-color: transparent;
  --sg-modal-divider-color: #9c9ca2;
  --sg-modal-upload-logo-background: rgba(174, 255, 132, 0.1);
  --sg-modal-upload-logo-background-hover: rgba(174, 255, 132, 0.15);
  --sg-modal-upload-logo-border: #aeff84;
  --sg-modal-upload-logo-svg: #aeff84;
  --sg-modal-input-icon-color: #aeff84;
  --sg-disabled-text-color: #9c9ca2;
  --sg-background-image: url('https://static.parastorage.com/services/santa-resources/dist/editor/siteGeneration/sg-newui-background-dark.png');
  --sg-background-gradient: linear-gradient(77deg, #d5d3d300 70%, #71717163 99%),
    linear-gradient(121deg, #496d7ac9 0%, #4d6b7900 22%);
  --sg-site-preview-background: rgba(167, 165, 165, 0.1);
  --sg-site-preview-shadow: 0px 22px 67px 0px rgba(246, 255, 237, 0.47);
  --sg-site-preview-decor-svg: #9c9ca2;
  --sg-site-preview-device-selected-background: #515254;
  --sg-site-preview-device-selected: #aeff84;
  --sg-site-preview-device-not-selected: #9c9ca2;
  --sg-checkbox-background: #aeff84;
  --sg-checkbox-hover: #aeff8426;
  --sg-dropdown-selected: #25252a;
  --sg-checkbox-background-disabled: #9c9ca2;
  --sg-checkbox-border-disabled: #9c9ca2;
  --sg-checkbox-icon-color: #000000;
  --sg-checkbox-icon-color-disabled: #cfd1dc;
  --sg-dots-loader-background-color: #25282a;
  --sg-dots-loader-dot-background-color: #999ca2;
  --sg-modal-footer-primary-button-background: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-background-hover: linear-gradient(
    162.83deg,
    #ffffff 16.52%,
    #c5ffa7 55.07%,
    #aeff84 113.92%
  );
  --sg-modal-footer-primary-button-text: #000000;
  --sg-modal-footer-secondary-button-text: #ffffff;
  --sg-modal-footer-secondary-button-text-hover: #ffffffbf;
  --sg-wix-logo-colors: #ffffff;
  --sg-final-step-divider: #9c9ca2;
  --sg-final-step-input-border: #aeff84;
  --sg-final-step-input-background: #000000;
  --sg-forms-modal-background: linear-gradient(
      131deg,
      #5f8ea0b3 1%,
      #5f8ea04a 27%,
      #00000000 69%
    ),
    linear-gradient(0deg, #17181b 1%, #17181b 99%);
  --sg-modal-backdrop: rgba(0, 0, 0, 0.4);
  --sg-modal-card-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.2);
  --sg-tooltio-background: #25252a;
  --sg-tooltip-text-color: #ffffff; }

._mainWrapper_j7nu7_75 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: transform 1s cubic-bezier(0.83, 0, 0.17, 1); }
  ._mainWrapper_j7nu7_75 ._bg_j7nu7_79 {
    position: absolute;
    inset: 0;
    z-index: -1; }
  ._mainWrapper_j7nu7_75._showFinalStep_j7nu7_83 {
    transform: translate(calc(-1 * clamp(480px, 33vw, 680px)), 0); }
  ._mainWrapper_j7nu7_75 ._mainGrid_j7nu7_85 {
    width: calc(100vw + clamp(480px, 33vw, 680px));
    height: 100vh;
    display: grid;
    grid-template-columns: clamp(480px, 33vw, 680px) calc(100vw - clamp(480px, 33vw, 680px)) clamp(480px, 33vw, 680px); }
    ._mainWrapper_j7nu7_75 ._mainGrid_j7nu7_85 ._gridCell_j7nu7_90 {
      height: 100vh;
      display: flex;
      flex-direction: column; }
      ._mainWrapper_j7nu7_75 ._mainGrid_j7nu7_85 ._gridCell_j7nu7_90._preview_j7nu7_94 {
        justify-content: center;
        align-items: center; }

._sitePreview_j7nu7_98 {
  width: calc(100% - 2 * 48px);
  max-height: calc(100% - 36px);
  margin: 36px;
  padding: 0px 12px 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: var(--sg-site-preview-background, rgba(140, 155, 193, 0.06));
  z-index: calc(100 + 1);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border: solid 1px rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  box-shadow: var(--sg-site-preview-shadow, 20px 24px 84px 0px rgba(81, 84, 98, 0.5));
  position: relative;
  transition: max-width 1s ease-in-out, height 1s ease-in-out; }
  ._sitePreview_j7nu7_98._desktop_j7nu7_114 {
    max-width: 100%;
    height: calc(100% - 36px);
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: none; }
    ._sitePreview_j7nu7_98._desktop_j7nu7_114 ._loader_j7nu7_122 {
      inset: 42px 12px 0px; }
  ._sitePreview_j7nu7_98._mobile_j7nu7_124 {
    max-width: 344px;
    height: 700px; }
    ._sitePreview_j7nu7_98._mobile_j7nu7_124 ._loader_j7nu7_122 {
      inset: 42px 12px 12px; }
  ._sitePreview_j7nu7_98 ._sitePreviewInner_j7nu7_129 {
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border: 1px solid #ffffff1a;
    border-radius: 3px;
    border-bottom: none;
    flex: 1; }
    ._sitePreview_j7nu7_98 ._sitePreviewInner_j7nu7_129::-webkit-scrollbar {
      display: none; }
  ._sitePreview_j7nu7_98 ._loader_j7nu7_122 {
    height: unset;
    width: unset; }
