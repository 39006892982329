._skeletonLoader_a92ln_1 {
  box-sizing: border-box;
  padding: 24px 24px;
  height: 100%; }

svg._textLoader_a92ln_6 {
  overflow: unset;
  color: #fff; }

svg._textLoader_a92ln_6._play_a92ln_10 {
  animation-name: _fade-out_a92ln_1;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite; }

@keyframes _fade-out_a92ln_1 {
  0% {
    opacity: 1; }
  86.5229110512% {
    opacity: 1; }
  100% {
    opacity: 0; } }

._textLoader_a92ln_6._play_a92ln_10 rect {
  height: 16px;
  opacity: 0;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
  animation-fill-mode: forwards;
  animation-iteration-count: infinite; }

._rect1_a92ln_32 {
  animation-name: _rect1-a_a92ln_1; }

@keyframes _rect1-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  3.59164% {
    opacity: 1;
    color: #a8c9ff; }
  7.90162% {
    opacity: 1;
    color: #d6e6ff; }
  14.36658% {
    width: 65px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 65px;
    opacity: 1;
    color: #e7f0ff; } }

._rect2_a92ln_59 {
  animation-name: _rect2-a_a92ln_1; }

@keyframes _rect2-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  4.43333% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  8.02498% {
    opacity: 1;
    color: #a8c9ff; }
  12.33495% {
    opacity: 1;
    color: #d6e6ff; }
  18.79991% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect3_a92ln_86 {
  animation-name: _rect3-a_a92ln_1; }

@keyframes _rect3-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  8.86667% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  12.45831% {
    opacity: 1;
    color: #a8c9ff; }
  16.76828% {
    opacity: 1;
    color: #d6e6ff; }
  23.23324% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect4_a92ln_113 {
  animation-name: _rect4-a_a92ln_1; }

@keyframes _rect4-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  13.3% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  16.89164% {
    opacity: 1;
    color: #a8c9ff; }
  21.20162% {
    opacity: 1;
    color: #d6e6ff; }
  27.66658% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect5_a92ln_140 {
  animation-name: _rect5-a_a92ln_1; }

@keyframes _rect5-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  17.73333% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  21.32498% {
    opacity: 1;
    color: #a8c9ff; }
  25.63495% {
    opacity: 1;
    color: #d6e6ff; }
  32.09991% {
    width: 163px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 163px;
    opacity: 1;
    color: #e7f0ff; } }

._rect6_a92ln_167 {
  animation-name: _rect6-a_a92ln_1; }

@keyframes _rect6-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  22.16667% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  25.75831% {
    opacity: 1;
    color: #a8c9ff; }
  30.06828% {
    opacity: 1;
    color: #d6e6ff; }
  36.53324% {
    width: 65px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 65px;
    opacity: 1;
    color: #e7f0ff; } }

._rect7_a92ln_194 {
  animation-name: _rect7-a_a92ln_1; }

@keyframes _rect7-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  26.6% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  30.19164% {
    opacity: 1;
    color: #a8c9ff; }
  34.50162% {
    opacity: 1;
    color: #d6e6ff; }
  40.96658% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect8_a92ln_221 {
  animation-name: _rect8-a_a92ln_1; }

@keyframes _rect8-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  31.03333% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  34.62498% {
    opacity: 1;
    color: #a8c9ff; }
  38.93495% {
    opacity: 1;
    color: #d6e6ff; }
  45.39991% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect9_a92ln_248 {
  animation-name: _rect9-a_a92ln_1; }

@keyframes _rect9-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  35.46667% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  39.05831% {
    opacity: 1;
    color: #a8c9ff; }
  43.36828% {
    opacity: 1;
    color: #d6e6ff; }
  49.83324% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect10_a92ln_275 {
  animation-name: _rect10-a_a92ln_1; }

@keyframes _rect10-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  39.9% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  43.49164% {
    opacity: 1;
    color: #a8c9ff; }
  47.80162% {
    opacity: 1;
    color: #d6e6ff; }
  54.26658% {
    width: 163px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 163px;
    opacity: 1;
    color: #e7f0ff; } }

._rect11_a92ln_302 {
  animation-name: _rect11-a_a92ln_1; }

@keyframes _rect11-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  44.33333% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  47.92498% {
    opacity: 1;
    color: #a8c9ff; }
  52.23495% {
    opacity: 1;
    color: #d6e6ff; }
  58.69991% {
    width: 65px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 65px;
    opacity: 1;
    color: #e7f0ff; } }

._rect12_a92ln_329 {
  animation-name: _rect12-a_a92ln_1; }

@keyframes _rect12-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  48.76667% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  52.35831% {
    opacity: 1;
    color: #a8c9ff; }
  56.66828% {
    opacity: 1;
    color: #d6e6ff; }
  63.13324% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect13_a92ln_356 {
  animation-name: _rect13-a_a92ln_1; }

@keyframes _rect13-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  53.2% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  56.79164% {
    opacity: 1;
    color: #a8c9ff; }
  61.10162% {
    opacity: 1;
    color: #d6e6ff; }
  67.56658% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect14_a92ln_383 {
  animation-name: _rect14-a_a92ln_1; }

@keyframes _rect14-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  57.63333% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  61.22498% {
    opacity: 1;
    color: #a8c9ff; }
  65.53495% {
    opacity: 1;
    color: #d6e6ff; }
  71.99991% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 320px;
    opacity: 1;
    color: #e7f0ff; } }

._rect15_a92ln_410 {
  animation-name: _rect15-a_a92ln_1; }

@keyframes _rect15-a_a92ln_1 {
  0% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  62.06667% {
    width: 10px;
    opacity: 0;
    color: #a8c9ff; }
  65.65831% {
    opacity: 1;
    color: #a8c9ff; }
  69.96828% {
    opacity: 1;
    color: #d6e6ff; }
  76.43324% {
    width: 163px;
    opacity: 1;
    color: #e7f0ff; }
  100% {
    width: 163px;
    opacity: 1;
    color: #e7f0ff; } }
