:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._wrapper_d4fjb_7 {
  box-sizing: border-box;
  padding: 12px;
  height: 135px;
  border-radius: 4px;
  border: 1px solid #e1e3e6;
  background-color: var(--background); }

._topContainer_d4fjb_15 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px; }
  ._topContainer_d4fjb_15 ._leftColumn_d4fjb_19 {
    display: flex;
    flex-direction: column; }
  ._topContainer_d4fjb_15 ._rightColumn_d4fjb_22 {
    display: flex;
    flex-direction: column; }

._bottomContainer_d4fjb_26 {
  display: flex;
  justify-content: space-between;
  margin-top: 12px; }

._title_d4fjb_31 {
  font-family: var(--heading-font);
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--title); }

._subtitle_d4fjb_39,
._links_d4fjb_40,
._bodyText_d4fjb_41,
._secondaryText_d4fjb_42 {
  font-family: var(--paragraph-font);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; }

._subtitle_d4fjb_39 {
  margin-bottom: 4px;
  color: var(--subtitle); }

._bodyText_d4fjb_41 {
  color: var(--body-text); }

._secondaryText_d4fjb_42 {
  color: var(--secondary-text); }

._links_d4fjb_40 {
  color: var(--links); }

._divider_d4fjb_61 {
  width: 100%;
  height: 1px;
  background-color: var(--line); }

._button_d4fjb_66 {
  font-family: var(--paragraph-font);
  height: 30px;
  width: 112px;
  border-radius: 0;
  border: 1px solid #000000;
  font-size: 12px; }
  ._buttonPrimary_d4fjb_73 {
    margin-bottom: 8px;
    background-color: var(--primary-btn-bg);
    border-color: var(--primary-btn-border);
    color: var(--primary-btn-color); }
    ._buttonPrimary_d4fjb_73:hover {
      background-color: var(--primary-btn-bg-hover);
      border-color: var(--primary-btn-border-hover);
      color: var(--primary-btn-color-hover); }
  ._buttonSecondary_d4fjb_82 {
    background-color: var(--secondary-btn-bg);
    border-color: var(--secondary-btn-border);
    color: var(--secondary-btn-color); }
    ._buttonSecondary_d4fjb_82:hover {
      background-color: var(--secondary-btn-bg-hover);
      border-color: var(--secondary-btn-border-hover);
      color: var(--secondary-btn-color-hover); }
