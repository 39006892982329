:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_17121_7 {
  display: flex;
  align-items: center;
  height: 84px;
  border-bottom: 1px solid #dfe5eb; }
  ._container_17121_7:hover, ._container_17121_7._isEditing_17121_12 {
    background-color: #e7f0ff;
    cursor: pointer; }
    ._container_17121_7:hover ._editIcon_17121_15, ._container_17121_7._isEditing_17121_12 ._editIcon_17121_15 {
      display: flex; }
  ._container_17121_7:last-child {
    border-bottom: none; }

._colorIndicator_17121_20 {
  width: 22px;
  height: 22px;
  margin: 0 19px 0 10px;
  border: 1px solid #dfe5eb;
  border-radius: 12px; }

._fontPreview_17121_27 {
  max-width: 160px; }

._fontInfo_17121_30 {
  display: flex;
  flex-direction: column;
  justify-content: center; }

._additionalInfo_17121_35 {
  display: flex;
  max-width: 160px; }
  ._additionalInfo_17121_35:hover div {
    cursor: pointer; }

._font_17121_27 {
  line-height: 1.4; }

._fontFamily_17121_44 {
  max-width: 120px; }

._editIcon_17121_15 {
  display: none;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 10px; }
  ._editIcon_17121_15 path {
    fill: #116dff; }
