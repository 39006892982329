._site-canvas-out_mkmkp_1 {
  animation-duration: 0s;
  animation-name: _canvas-out_mkmkp_1;
  opacity: 0;
  position: absolute; }

@keyframes _canvas-out_mkmkp_1 {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
