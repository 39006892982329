._briefActions_1vwbe_1 {
  display: flex;
  flex-direction: row;
  gap: 18px; }

._briefFooter_1vwbe_6 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s ease-in-out; }

._open_1vwbe_14 {
  max-height: 300px;
  transition: max-height 1s ease-in-out; }

._briefMessagesWrapper_1vwbe_18 {
  display: flex;
  flex-direction: column;
  gap: 12px; }

._briefLoader_1vwbe_23 {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%; }

._briefContent_1vwbe_32 {
  display: flex;
  flex-direction: column;
  gap: 18px; }

._briefReadMode_1vwbe_37 {
  gap: 18px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: column; }
  ._briefReadMode_1vwbe_37 > div {
    gap: 18px; }
  ._briefReadMode_1vwbe_37 span {
    line-height: 32px !important; }

._messageAboveBrief_1vwbe_47 span {
  line-height: 32px !important;
  white-space: pre-wrap; }

._hidden_1vwbe_51 {
  visibility: hidden; }
