._siteCreationChooseKitMain_i2ufr_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

._siteCreationChooseKit_i2ufr_1 {
  width: 100%;
  height: calc(100% - 102px);
  display: flex; }
  @media only screen and (max-height: 900px) {
    ._siteCreationChooseKit_i2ufr_1 {
      height: calc(100% - 82px); } }

._siteCreationChooseKitContent_i2ufr_16 {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }

._title_i2ufr_23 {
  margin: auto; }

._kitsList_i2ufr_26 {
  width: 504px;
  min-width: 504px;
  height: 480px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  transform-origin: center;
  overflow: visible; }
  @media only screen and (min-width: 1600px) {
    ._kitsList_i2ufr_26 {
      padding: 0 calc( (100vw / 1920 * 175)); } }
