._mainWrapper_1szfq_1 {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: transform 1s cubic-bezier(0.83, 0, 0.17, 1); }
  ._mainWrapper_1szfq_1 ._bg_1szfq_5 {
    position: absolute;
    inset: 0;
    z-index: -1; }
  ._mainWrapper_1szfq_1._showFinalStep_1szfq_9 {
    transform: translate(calc(-1 * clamp(480px, 33vw, 680px)), 0); }
  ._mainWrapper_1szfq_1 ._mainGrid_1szfq_11 {
    width: calc(100vw + clamp(480px, 33vw, 680px));
    height: 100vh;
    display: grid;
    grid-template-columns: clamp(480px, 33vw, 680px) calc(100vw - clamp(480px, 33vw, 680px)) clamp(480px, 33vw, 680px); }
    ._mainWrapper_1szfq_1 ._mainGrid_1szfq_11 ._gridCell_1szfq_16 {
      height: 100vh;
      display: flex;
      flex-direction: column; }
      ._mainWrapper_1szfq_1 ._mainGrid_1szfq_11 ._gridCell_1szfq_16._preview_1szfq_20 {
        justify-content: center;
        align-items: center; }
