@keyframes _smoothAppear_90yau_1 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._loadingPalettesContainer_90yau_7 {
  animation-name: _expand_90yau_1;
  animation-timing-function: linear;
  animation-duration: 2s; }

@keyframes _expand_90yau_1 {
  from {
    height: 60px; }
  to {
    height: 156px; } }

@keyframes _paletteFromLogo_fadeIn_90yau_1 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

._loadingPalettes_90yau_7 {
  animation-name: _paletteFromLogo_fadeIn_90yau_1;
  animation-duration: 1s;
  animation-fill-mode: backwards; }
  ._loadingPalettes_90yau_7:not(:last-child) {
    margin-bottom: 24px; }
  ._loadingPalettes_90yau_7:first-child {
    margin-top: 18px; }

._loadingPalettes_90yau_7:nth-child(2) {
  animation-delay: 500ms; }

._loadingPalettes_90yau_7:nth-child(3) {
  animation-delay: 1500ms; }

._loadingPalettes_90yau_7:nth-child(4) {
  animation-delay: 2000ms; }

._generatedPalettes_90yau_42:not(:last-child) {
  margin-bottom: 24px; }

._generatedPalettes_90yau_42:first-child {
  margin-top: 18px; }

._promptsVersion_90yau_48 {
  margin: 24px 0px; }
