:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._input_137oh_7 {
  border-radius: 6px;
  background: white;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  border: 1px solid #d6e6fe; }
  ._input__button_137oh_13 {
    width: 30px;
    height: 30px;
    padding: 7.5px 0 0 8px;
    border-radius: 8px;
    background: linear-gradient(79deg, #116dff 36%, #2fffe6 119.85%);
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    right: 9px;
    bottom: 50%;
    transform: translate(0, 50%); }
    ._input__button_customPosition_137oh_25 {
      bottom: 9px;
      transform: none; }
  ._input__placeholder_137oh_28 {
    display: none;
    color: #b6c1cd;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--wbu-font-stack);
    font-weight: var(--wbu-font-weight-regular);
    position: absolute;
    top: 12px;
    left: 12px;
    pointer-events: none; }
  ._input__contentEditable_137oh_41 {
    min-height: 18px;
    max-height: 72px;
    padding: 12px 8px 12px 12px;
    overflow-y: scroll;
    font-family: var(--wbu-font-stack);
    font-weight: var(--wbu-font-weight-regular);
    position: relative;
    margin-right: 54px; }
    ._input__contentEditable_137oh_41 *:not([class='prompt-placeholder']) {
      color: #000624 !important; }
    ._input__contentEditable_137oh_41:empty:not(:focus-within) ~ ._input__placeholder_137oh_28 {
      display: block; }
    ._input__contentEditable_137oh_41::-webkit-scrollbar {
      width: 6px; }
    ._input__contentEditable_137oh_41::-webkit-scrollbar-thumb {
      background-color: #cfd1dc;
      border-radius: 4px; }
