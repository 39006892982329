._siteInfoLabel_1j8zn_1 {
  font-size: 14px;
  line-height: 18px;
  color: #333853;
  margin-bottom: 6px;
  margin-top: 0; }

._siteInfoContentArea_1j8zn_8 > input {
  border: 1px solid #d6e6fe !important;
  border-radius: 4px !important;
  padding: 6px 9px 6px 12px;
  font-size: 16px !important;
  box-sizing: border-box;
  width: 100%; }

._basicInfoArea_1j8zn_16 {
  display: flex;
  flex-direction: row; }

._textInfoArea_1j8zn_20 {
  margin-right: 24px; }

._siteInfoField_1j8zn_23 {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  margin-top: 6px; }

._logoInput_1j8zn_29 {
  display: flex;
  justify-content: center; }
  ._logoInput_1j8zn_29:hover ._logoTooltip_1j8zn_32 {
    display: inline-block; }
