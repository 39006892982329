:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._root_1am2c_7 {
  transition: opacity 0.3s ease-in-out; }
  ._root_1am2c_7._root_done_appear_1am2c_9 {
    opacity: 1 !important; }
  ._root_1am2c_7._root_done_enter_1am2c_11 {
    opacity: 1 !important; }
  ._root_1am2c_7._root_done_exit_1am2c_13 {
    opacity: 0 !important; }
  ._root_1am2c_7 ._container_1am2c_15 {
    width: 320px;
    display: flex;
    flex-grow: 1;
    opacity: 1 !important;
    transition: width 0.2s ease-in-out, opacity 0.3s ease-in-out; }
  ._root_1am2c_7 ._container_view_background_1am2c_21 {
    width: 352px; }
  ._root_1am2c_7 .panel-header {
    border-top-color: #ff9900; }
  ._root_1am2c_7 ._ai_text_1am2c_25 {
    background-image: linear-gradient(60deg, #0021ff 0, #116dff 50%, #5ff2ae 100%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text; }
  ._root_1am2c_7 ._ai_text_wrapper_1am2c_30 {
    width: 94px; }
