:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._message_8s2ap_7 {
  padding: 18px 18px 18px 12px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid #dfe5eb;
  background: white; }
  ._message__wrapper_8s2ap_12 {
    display: flex;
    flex-direction: column; }
  ._message__suggestion_8s2ap_15 {
    padding-top: 6px; }
  ._message_user_8s2ap_17 {
    border-radius: 8px 0px 8px 8px;
    border: 1px solid #a8caff;
    background: #e7f0ff;
    padding: 12px 18px;
    margin-left: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    ._message_user_oneLongWord_8s2ap_24 {
      overflow-wrap: anywhere; }
  ._message__content_8s2ap_26 {
    display: flex;
    gap: 0 6px; }
    ._message__content_8s2ap_26 * {
      -webkit-user-select: text;
              user-select: text; }
