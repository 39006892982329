._controls_8ykxk_1 {
  display: flex;
  justify-content: right;
  margin-top: 12px;
  padding-left: 24px; }
  ._controls__left_8ykxk_6 {
    display: flex;
    justify-content: space-between;
    gap: 3px; }
  ._controls__right_8ykxk_10 {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    gap: 12px; }
