._chat_8q9he_8 {
  height: calc(100vh - 20px * 2);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box; }
  ._chat_8q9he_8._actionButtonsVisible_8q9he_17 {
    padding-bottom: 0; }

._footerWrapper_8q9he_20 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 590px;
  display: flex;
  justify-content: flex-end; }

._conversation_8q9he_28 {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
  gap: 6px;
  align-self: center;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  width: 100%; }
  ._conversation_8q9he_28::-webkit-scrollbar {
    display: none; }

._mainConversation_8q9he_43:first-child {
  margin-top: 84px; }

._mainChatBottom_8q9he_46 {
  height: 120px; }

._miniChatBottom_8q9he_49 {
  height: 60px; }

._conversationContent_8q9he_52 {
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

._message_8q9he_57 {
  padding: 3px 0;
  display: flex;
  flex-direction: column;
  width: 100%; }
